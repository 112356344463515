import axios from "axios";
import React, { useCallback, useRef } from "react";
import { useState, useEffect} from "react";
import {formatWithValues, getKeyPath, isString} from "../utils.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

/*
	- calls prop.endpoint on every change
	- displays search results in a dropdown
	- displays selected values at the bottom with a checkbox
*/
export function SuggestedField({selected, onSelect, onSearch, className, input_className, props:_props}){

	/* defaults */
	const [props] = useState(_props || {}); /* endpoint, min_search_text_length, min_selections, max_selections, placeholder */
	const [selected_values, setSelectedValues] = useState(selected || []);


	const filteredSearchResults = function(search_results){
		if(!search_results?.length) return search_results;
		/* tries to find an id path */
		let id_path = props.id_key_path ? props.id_key_path.split(".") : (search_results[0]._id ? ["_id"] : null);
		if(!id_path) return search_results;
		let selected_ids = selected_values.map((item) => getKeyPath(item, id_path));
		return search_results.filter((item) => !selected_ids.includes(getKeyPath(item, id_path)));

	}
	const [search_results, _setSearchResults] = useState(filteredSearchResults(props.selection_list));
	const setSearchResults = (search_results) => _setSearchResults(filteredSearchResults(search_results));
	const [is_loading, setIsLoading] = useState(false);
	const [search_text, setSearchText] = useState("");
	/*set preselection from answers map*/
	const ctx = useRef({}).current;

    const [has_input_text_error, setInputTextError] = useState(false);
	
	const triggerSearch = function(){
		if(
			props.endpoint
			&& search_text.length > (props.min_search_text_length || 3)
		){
			/* endpoint based search */
			setIsLoading(true);  // loading...
			axios({
				method: 'post',
				url: props.endpoint,
				data: {...(props.params || {}), "search_text": search_text},
			}).then(resp => {
				let search_results = getKeyPath(
					resp.data,
					props.results_key_path ? props.results_key_path.split(".") : null
				);
				setSearchResults(search_results);
			}).finally(() => {
				setIsLoading(false); // loading...
			});
		} else {
			/* static selection search */
			setSearchResults(props.selection_list?.filter(
				(item) => {
					const [title, description] = getTitleDescriptionImage(item, props)
					return title?.toLowerCase().includes(search_text?.toLowerCase())
					|| description?.toLowerCase().includes(search_text?.toLowerCase())
				}
			));
		}
	}
	/* 
		- query text changed 
		- call ajax get and setSearchResults

	*/
	useEffect(
		() => {
			if(onSearch && search_text.length > 0){
				onSearch(search_text); // call the onSearch callback
			}
			const timer = setTimeout(triggerSearch, 500);
			return () => clearTimeout(timer);
		}, [search_text, props]
	);

	/*
		a suggestion was deselected
	*/
	const removeSelectedValueCb = function(selected_value, evt){
		selected_values.splice(
			selected_values.indexOf(selected_value), 1
		);
		let new_selected_values = props.selected_values = [...selected_values];
		setSelectedValues(new_selected_values);
        onSelect && onSelect(new_selected_values);
	};

	/*
		- on suggestion selected
	*/
	const onSuggestionSelected = useCallback(function(search_result){
		if(props.max_selections){
			/*only max selections allowed values, overwrite*/
			while(selected_values.length > props.max_selections - 1){
				selected_values.shift();
			}
		}
		if(selected_values.some((item) => item._id ? item._id === search_result._id : item === search_result)){
			/* already selected */
			return;
		}
		let new_selected_values = props.selected_values = [...selected_values, search_result];
		setSelectedValues(new_selected_values);
		/*clear search results*/
		setSearchResults(null);
		/*clear search field*/
		setSearchText("")
        onSelect && onSelect(new_selected_values);        
	}, [selected_values, props]);

	return (
        <div className={`w3-row w3-relative ${className || ""}`}>
            { /*display field only when less than max selections*/
                (!props.max_selections ||  (selected_values.length < props.max_selections))
                    ? 	<div className={has_input_text_error ? "w3-border-red" : "" }>
                            <input autoComplete="off" type="text"
								onChange={(evt) => setSearchText(evt.target.value)}
								onBlur={(evt) => {
									clearTimeout(ctx.focus_timer);
									ctx.focus_timer = setTimeout(() => setSearchResults(null),500)
								}} /* if no timeout, it will remove results before the click triggers */
								onFocus={(evt) => {
									clearTimeout(ctx.focus_timer);
									triggerSearch();
								}}
                                className={input_className || "w3-input"}
								value={search_text}
                                placeholder={props.placeholder}
                                disabled={props.is_disabled}
                            />
							{is_loading ? <FontAwesomeIcon icon={faSpinner} className="w3-animate-spin w3-absolute" style={{right: 10, top: 12}} /> : null}
                        </div>
                    :   null
            }
            {
                <SuggestedSelectedValues 
                    selected_values={selected_values}
                    removeSelectedValueCb={removeSelectedValueCb}
                    props={props}
                />
            }
			{selected_values?.length && search_results?.length ? <hr />: null}
            { 
                <SuggestedSearchResults
                    search_results={search_results}
                    onSuggestionSelected={onSuggestionSelected}
					is_loading={is_loading}
					search_text={search_text}
                    props={props}
                />
            }
        </div>
	);
};


export function getTitleDescriptionImage(search_result, props){

	let title = search_result;
	let description = null;
	if(props.title_key_path){
		let title_key_path = props.title_key_path;
		title_key_path = title_key_path ? title_key_path.split(".") : ["title"];
		title = getKeyPath(search_result, title_key_path);
	}
	if(props.description_key_path){
		let description_key_path = props.description_key_path;
		description_key_path = description_key_path ? description_key_path.split(".") : ["description"];
		description = getKeyPath(search_result, description_key_path);
	}

	if(props.title_format){
		title = formatWithValues(props.title_format, search_result);
	}
	if(props.description_format){
		description = formatWithValues(props.description_format, search_result);
	}

	let image = null;
	let image_key_path = props.image_key_path;
	if(image_key_path !== null){ /* if explicitly null, then don't show images */
		image_key_path = image_key_path ? image_key_path.split(".") : ["image"];
		let image = getKeyPath(search_result, image_key_path);
		if(Array.isArray(image) && image.length > 0){
			image = image[0]; // pick the first image
		}
		if(!image && isString(props.default_image)) image = props.default_image;
		image = image?.url || image;
	}

	return [title, description, image];
}

/*
	- decodes title and description by key_path
	  and sets it on the field
*/
function SuggestedSearchResult({search_result, props}){

	let [title, description, image] = getTitleDescriptionImage(search_result, props);


	/*conditional render*/
	return (
		<div className="w3-flex-row w3-flex-vcenter">
			{
				image
				? 	<div className="w3-center w3-col s3 w3-padding-4">
						<img src={image} className="w3-image" alt={title || "image"} style={{"maxHeight": "75px"}}/>;
					</div>
				: 	props.image_key_path  // wants to display an image, but not image available
					? 	<div className="w3-flex w3-flex-vcenter w3-flex-hcenter w3-grey" 
							style={{"minHeight": "75px", "minWidth": "75px"}}
						>
							<div className="w3-tiny w3-text-white">No image</div>
						</div>
					: 	null
			}
			<div className="w3-padding-4 w3-flex-grow-s1 w3-align-middle">
			  	<label>{title}</label>
				{
					description 
						? <div className="w3-text-grey">{description}</div>
						: null
				}
			</div>
		</div>
	);
}

function SuggestedSelectedValues({selected_values, removeSelectedValueCb, props}){
	if(!selected_values || !selected_values.length){
		return null;
	}
	return 	<div className={`${props.selected_values_classname || "w3-padding-top-8 w3-list w3-list-bordered"} `}>
			{
				selected_values.map(
					(selected_value, _i) => (
						props.selected_renderer 
							? 	<React.Fragment key={_i}>
									{props.selected_renderer(selected_value, removeSelectedValueCb.bind(this, selected_value))}
								</React.Fragment>
							:	<SuggestedSelectedValue 
									key={_i}
									selected_value={selected_value}
									removeSelectedValueCb={removeSelectedValueCb.bind(this, selected_value)}
									props={props}
								/>
					)
				)
			}
			</div>

}

/*renderes a checkbox + search result*/
function SuggestedSelectedValue({selected_value, removeSelectedValueCb, props}){
	return (
		<label className="w3-flex">
            <div className="w3-padding-topbottom-8 w3-padding-right">
                <input type="checkbox" 
                    checked 
                    className="w3-check" 
                    onChange={removeSelectedValueCb}
                />
            </div>
			{
				props.selected_renderer 
				? props.selected_renderer(selected_value) 
				: props.result_renderer 
					? props.result_renderer(selected_value)
					: <div><SuggestedSearchResult search_result={selected_value} props={props} /></div>
			}
		</label>
	);
}


function SuggestedSearchResults({search_results, is_loading, onSuggestionSelected, search_text, props}){
	if(!search_results) return null;

	return (
        <div className="w3-relative">
            <div className={`w3-display-top w3-2px-shadow w3-bold w3-round-small w3-white w3-list w3-scroll ${props.search_result_className || ''}`} 
				style={{"maxHeight": "400px", "overflow":"auto"}}
			>
            {
                is_loading
                    ? <div className="w3-white w3-padding-8 w3-center">Loading ...</div> 
					: !props.show_results_on_render && search_text.length == 0 ? null 
                    : search_results.length === 0
                        ? 	props.hide_no_results 
							? null
							: <div className="w3-white w3-padding-8 w3-red">No results for your query</div>
                        : 	search_results.map(
								(search_result, _i) => 
									<div className="w3-white" key={_i} 
										onClick={() => {onSuggestionSelected(search_result)}}
									>
										{
											props.result_renderer 
											? 	props.result_renderer(search_result) 
											:	<SuggestedSearchResult
													search_result={search_result}
													props={props}
												/>
										}
									</div>
							)
            }
            </div>
		</div>
	);
}
