import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { getParentCategories } from '../../../helpers/CategoriesHelper';

const CategoryChip = ({category, is_selected, onClick, className}) => {
    const category_name = category.name.toLowerCase();
    return (
        <div className={
            `tw-flex tw-shrink-0 tw-items-center tw-cursor-pointer ${className} 
                ${
                    is_selected
                    ? "tw-border-secondary-lighter tw-text-secondary-lighter" 
                    : ""
                }
            `
        }
        onClick={onClick}
         >
            {category_name.includes('promo') || category_name.includes('discount') || category_name.includes('offer') 
                ? <img src={'https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-08-09/1691562006VmR3OsIWPR__offer.png'} className='tw-mr-1 tw-w-5' /> 
                : null
            }
            <span dangerouslySetInnerHTML={{__html: category.name}} className='tw-capitalize' />
        </div>
    )
}

const CategoryBar = ({list, selected, setSelected}) => {
    return list.map(c => (
        <CategoryChip key={c.name} 
            category={c} 
            is_selected={c.name === selected?.name} 
            onClick={() => setSelected(c.name === selected?.name ? null : c)} 
            className={`${c.name === selected?.name && 'tw-border-b-2'} tw-capitalize tw-text-gray-600 tw-text-[13px] tw-pt-1 tw-pb-2`} 
        />
    ))
}

const CategoryChipsBar = ({list, selected, setSelected}) => {
    const className = 'tw-border tw-rounded tw-px-2 tw-py-0.5 tw-text-xs tw-text-gray-500';
    return <div className='tw-px-4 tw-capitalize tw-px-4 tw-flex tw-items-center tw-text-sm tw-gap-3 tw-overflow-x-auto w3-hide-scrollbar'>
        {selected.map(
            (c) => <CategoryChip key={c.name} category={c} is_selected={true} onClick={() => setSelected(c.parent || null)} className={className}  />
        )}
        {selected.length && list.length ? <FontAwesomeIcon icon={faCaretRight} className='tw-text-gray-500' /> : null}
        {list.map(
            (c) => <CategoryChip key={c.name} category={c} is_selected={false} onClick={() => setSelected(c)} className={className}  />
        )}
    </div>
}

const CategoriesPopup = ({list, selected, onApply}) => {
    const [selected_category, setSelectedCategory] = useState(selected);
    const selected_category_tree = getParentCategories(selected_category?.sub_cats?.length ? selected_category : selected_category?.parent);
    const _list = selected_category 
        ? selected_category.sub_cats?.length 
            ? selected_category.sub_cats 
            : selected_category.parent ? selected_category.parent.sub_cats : list
        : list;

    const selectCategory = (c) => {
        const new_selected = c.name === selected_category?.name ? (selected_category.parent || null) : c;
        setSelectedCategory(new_selected)
        if (!new_selected.sub_cats?.length) {
            onApply(new_selected);
        }
    }

    return (
        <>
            {selected_category_tree.length 
                ?   <div className='tw-flex tw-flex-wrap tw-items-center tw-bg-gray-bg tw-border-b tw-px-3 tw-py-1.5 tw-gap-4'>
                        <div className='tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-secondary-lighter' onClick={() => setSelectedCategory(null)}>
                            <span>All</span><FontAwesomeIcon icon={faCaretRight} />
                        </div>
                        {
                            selected_category_tree.map((c, i) => (
                                <div key={c.name} className={`tw-flex tw-items-center tw-gap-1 tw-text-xs ${i==selected_category_tree.length-1 ? 'tw-text-gray-600' : 'tw-text-secondary-lighter'}`} onClick={() => setSelectedCategory(c)}>
                                    <span className='tw-capitalize' dangerouslySetInnerHTML={{__html: c.name}} />
                                    <FontAwesomeIcon icon={faCaretRight} className={i == selected_category_tree.length - 1 ? 'tw-text-gray-400' : ''} />
                                </div>
                            ))
                        }
                    </div> 
                :   null
            }
            <ul className='tw-pt-2 tw-min-h-[40vh] tw-pb-20 tw-min-w-[50ch]'>
                {_list.map((c) => (
                    <li className={`${c.name === selected_category?.name && 'tw-text-secondary-lighter'} tw-flex tw-py-3 tw-px-4`} key={c.name}
                        onClick={() => selectCategory(c)}
                    >
                        <span className='tw-capitalize' dangerouslySetInnerHTML={{__html: c.name}} />
                    </li>
                ))
                }
            </ul>
            <div className='tw-px-4 tw-absolute tw-bottom-0 tw-w-full tw-grid tw-grid-cols-2 tw-gap-2 tw-bg-white tw-py-2'>
                <button className='tw-text-sm tw-border tw-px-4 tw-py-2 tw-w-full tw-rounded tw-text-gray-600 tw-border-gray-300'
                    onClick={() => {onApply(null, !!selected_category?.parent); setSelectedCategory(null)}}
                >
                    Reset
                </button>
                <button className='tw-text-sm tw-bg-secondary-lighter tw-px-4 tw-py-2 tw-w-full tw-rounded tw-text-white'
                    onClick={() => onApply(selected_category)}
                >
                    Apply
                </button>
            </div>
        </>
    )
}

export {CategoriesPopup, CategoryChipsBar, CategoryBar}