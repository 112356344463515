import React from "react";
import { ThumbnailFileView } from "base/ui/misc";

function WAPreview({
  files,
  title,
  text,
  footer_text,
  button_payloads,
  onSend,
  show_button_type,
  category,
  status,
}) {
  return (
    <div className="tw-bg-[#F1E8E1] tw-rounded-xl tw-border ">
      <div className="tw-flex tw-items-end tw-justify-between tw-bg-white tw-rounded-t-xl tw-border-b tw-px-4 tw-py-1 tw-text-black tw-text-xs">
        <div className=" tw-justify-center">
          <p className="tw-break-all tw-font-bold">
            {title || "WhatsApp Preview"}
          </p>
          {category ? (
            <div className="tw-text-gray-400 tw-text-xxs">{category}</div>
          ) : null}
        </div>
        {status ? <div className="tw-text-xxs">{status}</div> : null}
      </div>
      <div className="tw-p-4">
        <div className="tw-flex tw-flex-col tw-space-y-2 tw-text-sm tw-max-w-xs tw-mx-1 tw-items-start">
          <div>
            <div className="tw-whitespace-pre-wrap tw-px-4 tw-py-2 tw-rounded-lg tw-inline-block tw-rounded-bl-none tw-bg-white tw-text-black tw-drop-shadow">
              <div className="tw-grid tw-justify-items-center">
                {files?.length > 0 && (
                  <ThumbnailFileView
                    className="tw-my-2 tw-h-[100px]"
                    file={files[0]?.url || files[0]}
                  />
                )}
              </div>
              <div
                className={`${
                  files?.length > 0 ? "tw-max-h-[120px]" : "tw-max-h-[200px]"
                } tw-overflow-scroll tw-text-xs`}
              >
                {text}
              </div>
              <div className="tw-text-gray-400 tw-mt-2 tw-text-xxs">
                {footer_text}
              </div>
            </div>
          </div>
          {button_payloads?.length ? (
            <div className="tw-flex tw-gap-1 tw-w-[100%] ">
              {button_payloads?.map((button, i) => {
                return (
                  <span
                    key={i}
                    className="tw-text-xxs tw-p-2 tw-rounded-lg tw-w-[100%] tw-inline-block tw-text-center tw-bg-white tw-text-sky-700 tw-drop-shadow"
                  >
                    {button.text}
                  </span>
                );
              })}
            </div>
          ) : null}
          {show_button_type && button_payloads?.length ? (
            <div className="tw-flex tw-gap-1 tw-w-[100%] ">
              {button_payloads?.map((button, i) => {
                return (
                  <span
                    key={i}
                    className="tw-text-xxs tw-px-2 tw-w-[100%] tw-inline-block tw-text-center tw-text-gray-400"
                  >
                    {button.type}
                  </span>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export { WAPreview };
