import React from 'react';
import { broadcaster } from 'base/utils/events';

function NoSlotsShortView({user}){
    return (
        <div className='w3-center'>
            <span className='w3-bold w3-text-red tw-text-sm'>Delivery closed</span>
            {
                user 
                ?   <div className='w3-tiny'>Slots will open shortly, we will notify you</div>
                :   <div className='w3-tiny' onClick={() => broadcaster.broadcast_event("do_login")}>
                        Login to get notified for new slots
                    </div>
            }
        </div>
    );
}

export default NoSlotsShortView