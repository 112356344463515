import React, { useEffect, useState } from "react";
import { getByIds } from "base/get_by_ids";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { DateView } from "base/ui/date";
import { createStaticRanges, DateRange, DateRangePicker, defaultStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { LOCAL_TZ_OFFSET_MILLIS } from "../../base/constants";

function LastUpdatedUser({ className, user_at }) {
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (!user_at) return;
		getByIds({ user_ids: [user_at.user_id] }).then((data) => {
			setUser(data.users[user_at.user_id]);
		});
	}, [user_at]);
	if (!user) return null;
	return (
		<div className={`tw-flex tw-gap-1 tw-text-xxs tw-text-gray-400 ${className}`}>
			<p>Last Updated by:</p>
			<p className="tw-text-black">{user.name}</p>
			<DateView millis={user_at.at} />
		</div>
	);
}

function EditUserById({ className, user_id, onEdit }) {
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (!user_id) return;
		getByIds({ user_ids: [user_id] }).then((data) => {
			setUser(data.users[user_id]);
		});
	}, [user_id]);
	if (!user) return null;
	return (
		<div
			className={`tw-flex tw-gap-1 tw-items-center ${className}`}
			onClick={(e) => {
				e.stopPropagation();
				onEdit();
			}}
		>
			<p className="tw-text-black">{user.name}</p>
			<FontAwesomeIcon icon={faPencil} className="tw-mr-2" />
		</div>
	);
}

const UserBadge = ({ user }) => (
	<div key={user._id} className={"tw-flex tw-py-2 tw-px-4 tw-items-center"}>
		<div className="tw-w-10 tw-h-10 tw-rounded-full flex-box tw-text-secondary tw-bg-secondary-bg tw-overflow-hidden">
			{!user.image ? (
				<span>{user.name?.slice(0, 1)}</span>
			) : (
				<img alt={user.name} src={user.image} className="tw-object-fill" />
			)}
		</div>
		<div className="tw-grow tw-px-2">
			<div className="tw-text-gray-600 tw-text-sm tw-font-bold">{user.name}</div>
			<div className="tw-text-gray-500 tw-text-sm">{user.phone_number || user.email_id}</div>
		</div>
	</div>
);

const CircularProgressBar = ({ total = 100, current = 0, styles }) => {
	const { height = 100, width = 100, color = "#15966E", strokeWidth: customStrokeWidth, fontSize } = styles;

	const size = Math.min(height, width);
	const strokeWidth = customStrokeWidth || size * 0.1; // 10% of size for stroke width if not provided
	const radius = (size - strokeWidth) / 2;
	const circumference = 2 * Math.PI * radius;
	const progress = total > 0 ? (current / total) * 100 : 0;
	const strokeDashoffset = circumference - (progress / 100) * circumference;

	const dynamicFontSize = fontSize || "11px";

	return (
		<div style={{ width: `${width}px`, height: `${height}px` }} className="tw-relative">
			<svg className="tw-w-full tw-h-full" viewBox={`0 0 ${size} ${size}`}>
				<circle
					className="tw-text-gray-200 tw-stroke-current"
					strokeWidth={strokeWidth}
					cx={size / 2}
					cy={size / 2}
					r={radius}
					fill="transparent"
				/>
				<circle
					className="tw-stroke-current"
					style={{ color }}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					cx={size / 2}
					cy={size / 2}
					r={radius}
					fill="transparent"
					strokeDasharray={circumference}
					strokeDashoffset={strokeDashoffset}
					transform={`rotate(-90 ${size / 2} ${size / 2})`}
				/>
			</svg>
			<div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
				<span style={{ fontSize: dynamicFontSize }} className="tw-font-bold">
					{current}
				</span>
			</div>
		</div>
	);
};

const RangeDatePicker = ({ start_time, end_time, onChange, from_days, to_days = -1 }) => {
	const [date_range, setDateRange] = useState([
		{
			startDate: new Date(start_time + LOCAL_TZ_OFFSET_MILLIS),
			endDate: new Date(end_time + LOCAL_TZ_OFFSET_MILLIS),
			key: "selection",
		},
	]);

	// Custom static ranges
	const customStaticRanges = [...defaultStaticRanges.filter((range) => range.label !== "Today")];

	return (
		<div className="tw-grid tw-justify-center tw-items-center">
			<DateRangePicker
				rangeColors={["#204540"]}
				months={1}
				calendarFocus="backwards"
				direction="horizontal"
				minDate={addDays(new Date(), -from_days)}
				maxDate={addDays(new Date(), to_days)}
				ranges={date_range}
				onChange={(date_range) => {
					setDateRange([date_range.selection]);
				}}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				preventSnapRefocus={true}
				editableDateInputs={true}
				startDatePlaceholder="Select Start Date"
				endDatePlaceholder="Select End Date"
				staticRanges={createStaticRanges(customStaticRanges)}
				inputRanges={[]}
			/>
			<div className="tw-flex tw-justify-end tw-p-4">
				<button
					className="tw-py-1 tw-px-4 tw-text-white tw-bg-[#15956E] tw-rounded"
					onClick={() => {
						onChange(date_range[0]);
					}}
				>
					Apply
				</button>
			</div>
		</div>
	);
};

// Renders only calender view without static ranges
const RangeDate = ({ start_time, end_time, onChange, from_days, to_days = -1 }) => {
	const [date_range, setDateRange] = useState([
		{
			startDate: new Date(start_time + LOCAL_TZ_OFFSET_MILLIS),
			endDate: new Date(end_time + LOCAL_TZ_OFFSET_MILLIS),
			key: "selection",
		},
	]);

	return (
		<div className="tw-grid tw-justify-center tw-items-center tw-pt-4">
			<DateRange
				rangeColors={["#204540"]}
				months={1}
				calendarFocus="backwards"
				direction="horizontal"
				minDate={addDays(new Date(), -from_days)}
				maxDate={addDays(new Date(), to_days)}
				ranges={date_range}
				onChange={(date_range) => {
					setDateRange([date_range.selection]);
				}}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				preventSnapRefocus={true}
				editableDateInputs={true}
				startDatePlaceholder="Select Start Date"
				endDatePlaceholder="Select End Date"
			/>
			<div className="tw-flex tw-justify-end tw-p-4">
				<button
					className="tw-py-1 tw-px-4 tw-text-white tw-bg-[#15956E] tw-rounded"
					onClick={() => {
						onChange(date_range[0]);
					}}
				>
					Apply
				</button>
			</div>
		</div>
	);
};

export { EditUserById, UserBadge, LastUpdatedUser, CircularProgressBar, RangeDatePicker, RangeDate };
