import React, { useEffect, useState } from 'react'
import { LoadingOverlay } from 'base/ui/status';
import { useSearchParams } from "react-router-dom";
import axios from 'axios'
import { GenericException } from "base/ui/errors";
import { useBroadcastedState } from 'base/utils/events';
import { getLocalStorage, setLocalStorage } from '../csm/base/utils';


const QuickbooksCallback = () => {
    const [search_params] = useSearchParams();
    const [is_loading, setLoading] = useState(false);
    const createQuickBooksConfig = () => {
        const response_state = search_params.get("state");
        const code = search_params.get("code");
        const realmId = search_params.get("realmId");
        const req = {
            'is_active':true,
            'code': code,
            'realm_id': realmId
        }
        const quickbooks_state = getLocalStorage('quickbooks_state')
        if(quickbooks_state !== response_state) {
            GenericException.showPopup("Quick books state id is not valid, so abondoning the quick books integration", "Error");
            return;
        }
        const delivery_org_id = response_state.split("_")[0]
        setLoading(true)
        axios.post(
            `/api/admin/delivery_org/${delivery_org_id}/quickbooks/setup`,
            req || {}
          ).then((resp) => {
                if(resp.data.errors) {
                    GenericException.showPopup(resp.data.errors, "Error");
                    return;
                }
                setLocalStorage('quickbooks_config', resp.data);
                setTimeout(() => {window.close()}, 2000)
            }).catch((err) => {
                GenericException.showPopup(err, "Error");
              })
            .finally(() => setLoading(false));

    }

    useEffect(
        () => {
            if(search_params.get("state")) {
                createQuickBooksConfig()
            }
        }, [search_params]
      );
    return (<div>
        {is_loading ? <LoadingOverlay title={"Please wait"} /> :  "Integration with Quickbooks is successful.This window will be closed shortly" }
        </div>);
}

export default QuickbooksCallback