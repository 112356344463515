import {ViewPort, IS_TOUCH_DEVICE} from "../constants"
import {animateScroll} from "../animations"
import { removeByValue } from "../utils";

/* listen to page scroll */
function addScrollListener(dom, cbs) {
    var _scroll_timer = null;// assume idle initially 
    if (dom._scroll_listeners) {
        dom._scroll_listeners.push(cbs);
        return;
    }
    dom._scroll_listeners = [cbs];
    let scroll_listener = function (evt) {
        // is actively scrolling check
        if (_scroll_timer) {
            clearTimeout(_scroll_timer);
        }
        _scroll_timer = setTimeout(function () {
            _scroll_timer = null;
            /*call all scroll listeners*/
            for (let i = 0; i < dom._scroll_listeners.length; i++) {
                let cbs = dom._scroll_listeners[i];
                let max_scroll = dom.scrollHeight - dom.clientHeight;
                if (cbs && cbs.on_scroll_stop) {
                    cbs.on_scroll_stop(dom.scrollTop, max_scroll);
                }
            }
            dom._scroll_state = 0;// IDLE
        }, 250);

        for (let i = 0; i < dom._scroll_listeners.length; i++) {
            let cbs = dom._scroll_listeners[i];
            if (cbs && cbs.on_scroll) {
                cbs.on_scroll(dom.scrollTop, dom.scrollHeight - dom.clientHeight);
            }
        }
        dom._scroll_state = 1;// SCROLLING
    };
    dom.addEventListener("scroll", scroll_listener, false);
    if (IS_TOUCH_DEVICE) {
        dom.addEventListener("touchmove", scroll_listener);
    }
    dom._scroll_listener = scroll_listener;
    cbs.trigger_on_start && scroll_listener();
    return;
};

function removeScrollLister(dom, cbs) {
    if (cbs) {
        removeByValue(dom._scroll_listeners, cbs);
    }
    if (!cbs || !dom._scroll_listeners?.length) {
        dom.removeEventListener("scroll", dom._scroll_listener);
        if (IS_TOUCH_DEVICE) {
            dom.removeEventListener("touchmove", dom._scroll_listener);
        }
        dom._scroll_listeners = null;
    }
}

function getScrollParent(element, direction = 'y') {
    if(element === window) return window;
    while (element && element !== document.body) {
        // Check if the element has overflow set to 'auto', 'scroll', or 'hidden'
        const style = window.getComputedStyle(element, null);
        if(style.position === 'fixed') return window;
        const overflow = direction === 'y' ? style.overflowY: style.overflowX;
        // Check if the element can scroll
        const scrollable_diff = direction === 'y' 
            ? element.scrollHeight - element.clientHeight
            : element.scrollWidth - element.clientWidth;
        
        if(overflow === 'scroll' && scrollable_diff >= 0) return element;
        if(overflow === 'auto' && scrollable_diff > 0) return element;
        element = element.parentElement;
    }
    /* check if body or documentElement is scrollable */
    let body_overflow = window.getComputedStyle(document.body, null).overflow;
    if(body_overflow === 'scroll' || body_overflow === 'auto') return document.body;
    let document_overflow = window.getComputedStyle(document.documentElement, null).overflow;
    if(document_overflow === 'scroll' || document_overflow === 'auto') return document.documentElement;
    return window;
}

/* tests if it's visible in viewport with options: {"bottom": true} and also scrolls to it when "scroll === true" is not set */
function visibleInViewport(elem, options) {
    if (!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)) {
        return;
    }
    const { HEIGHT:VIEWPORT_HEIGHT } = ViewPort;

    options = options || {};
    let elem_bounding_rect = elem.getBoundingClientRect();

    /*add the margin*/
    let scroll_parent = getScrollParent(elem);

    let is_visible = null;
    var scroll_to = null;

    options.offset_from_bottom = options.offset_from_bottom || 0;
    options.visibility = options.visibility || { "midpoint": true };

    if (options.visibility.bottom) {
        is_visible =
            elem_bounding_rect.bottom + options.offset_from_bottom <= VIEWPORT_HEIGHT &&
            elem_bounding_rect.bottom > 0;
        scroll_to = scroll_parent.scrollTop + elem_bounding_rect.bottom + options.offset_from_bottom - VIEWPORT_HEIGHT;
    }
    else if (options.visibility.midpoint) {
        let mid_point = (elem_bounding_rect.top + elem_bounding_rect.bottom) / 2 + options.offset_from_bottom;
        is_visible = mid_point < VIEWPORT_HEIGHT && mid_point > 0;
        scroll_to = scroll_parent.scrollTop + mid_point - VIEWPORT_HEIGHT / 2;
    }
    else if (options.visibility.top) {
        let top_point = elem_bounding_rect.top;
        is_visible = top_point > 0 && top_point < VIEWPORT_HEIGHT / 2;
        scroll_to = scroll_parent.scrollTop + top_point - VIEWPORT_HEIGHT / 2;
    }
    if (!is_visible) {
        if (options.scroll === true) {
            if (!options.quick_scroll) {
                /*TODO: change to smooth animated scrolling */
                animateScroll(scroll_parent, scroll_to);
            }
            else {
                scroll_parent.scrollTo(scroll_parent.scrollLeft, scroll_to);
            }
        }
        return false; // scrolling to viewport
    }

    return true;//already in viewport
}


var scrolled_to_bottom_checklist = null;
function addScrolledToBottomEventListener(elem, arg) {
    if (scrolled_to_bottom_checklist === null) {
        /* one time initialization */
        scrolled_to_bottom_checklist = [];
        addScrollListener(elem, {
            "on_scroll_stop": function (top, max_scroll) {
                for (let i = scrolled_to_bottom_checklist.length - 1; i >= 0; i--) {
                    /*check if we scrolled to this point*/
                    const [elem, cb] = scrolled_to_bottom_checklist[i];
                    visibleInViewport(
                        elem,
                        { "visibility": { "bottom": true }, "offset_from_bottom": -100 }
                    ) && cb(elem)
                }
            },
            "trigger_on_start": true
        });
    }
    /* track into list */
    scrolled_to_bottom_checklist.push([elem, arg]);
}

function removeScrolledToBottomEventListener(elem, arg) {
    if (!scrolled_to_bottom_checklist) return;
    let i = 0;
    while (i < scrolled_to_bottom_checklist.length) {
        let [el, _arg] = scrolled_to_bottom_checklist[i];
        /* if empty arg, we remove all listeners otherwise specific listener */
        if (el === elem && (arg == null || arg === _arg)) {
            scrolled_to_bottom_checklist.splice(i, 1);
        } else {
            i++;
        }
    }
}

export {
    addScrollListener, removeScrollLister,
    visibleInViewport, addScrolledToBottomEventListener,
    removeScrolledToBottomEventListener, getScrollParent
}
