import React, { useEffect, useState } from 'react'
import { ORDER_STATUS_DELIVERED } from '../../../../constants';
import { Popup } from 'base/ui/popups';
import PaymentOptions from './PaymentOptions';
import { getDisplayPrice, getPrice } from '../../../../helpers/StoreHelper';
import { useBroadcastedState } from 'base/utils/events';
import { useCurrentUser } from 'base/app';
import { FileUploader } from 'base/file_uploader';
import axios from 'axios';
import { GenericException } from 'base/ui/errors';
import { LoadingView } from 'base/ui/status';
import { DateView } from 'base/ui/date';
import { getRandomColor, objToEl } from 'base/utils/common';

function ConfirmOrderChange({order, onConfirm}) {
    const user = useCurrentUser()
    const [modify_request_reason, setModifyRequestReason] = useState(null);
    const [modify_request_files, setModifyRequestFiles] = useState(null);
    const [is_other_reason, setOtherReason] = useState(false);
    const [error, setError] = useState(false);

    const onReasonChange = (evt) => {
        const value = evt.target.value;
        if (value === 'OTHER') {
            setOtherReason(true);
        } else {
            setOtherReason(false);
            setModifyRequestReason(value);
        }
    }

    const onSubmit = () => {
        if (!modify_request_reason) {
            setError(true);
            return;
        }
        setError(false);
        onConfirm({
            "modify_request_reason": modify_request_reason,
            "modify_request_files": modify_request_files
        });
    }

    return (
        <div className='tw-p-4'>
            <label className='tw-text-gray-600 tw-text-xs tw-mb-2'>Reason for Change <span className='tw-text-red-500'>*</span></label>
            <div className='tw-mt-1 tw-mb-4'>
                <select className='tw-outline-0 tw-border tw-rounded tw-px-4 tw-py-2 tw-w-full tw-text-sm' onChange={onReasonChange}>
                    <option value=''>Select Reason</option>
                    <option value={'Customer Requested Change'}>Customer Requested Change</option>
                    <option value={'Customer Not Satifsied with quality'}>Customer Not Satifsied with quality</option>
                    <option value={'Item Missing'}>Item Missing</option>
                    {
                        user.roles.staff
                        || user.roles.superadmin 
                        || user.roles.manager?.[order.delivery_org_id]
                        ?   <option value={'admin_change'}>Admin Change</option>
                        :   null
                    }
                    <option value={'OTHER'}>Other</option>
                </select>
                {
                    is_other_reason
                    ?   <textarea className='tw-rounded tw-border tw-p-1 tw-w-full tw-mt-2 tw-text-sm' onChange={(evt) => setModifyRequestReason(evt.target.value)} rows={2} placeholder='Please specify reason' />
                    :   null
                }
                {error && !modify_request_reason ? <span className='tw-text-red-600 tw-text-xs'>Please specify reason</span> : null}
            </div>
            <label className='tw-text-gray-600 tw-text-xs'>Upload images</label>
            <div className='tw-mt-1 tw-mb-4'>
                <FileUploader files={modify_request_files || []}
                    allow_capture={true} onFilesUpdated={(files) => files ? setModifyRequestFiles(files.map(f => f.url)) : null} 
                />
            </div>
            <button className='tw-bg-secondary tw-text-white tw-rounded tw-px-4 tw-py-2 tw-text-sm' onClick={onSubmit}>Submit</button>
        </div>
    )
}

function PaymentTransactions({order}){
    const [payment_transactions, setPaymentTransactions] = useState(null);

    useEffect(
        () => {
            axios.get(`/api/payments/order/${order.delivery_route_id}/${order._id}`).then(
                (resp) => {
                    if(resp.data.errors){
                        GenericException.showPopup(resp.data.errors);
                        return;
                    }
                    setPaymentTransactions(resp.data.payment_transactions || []);
                }
            );
        }, []
    );

    if(payment_transactions == null){
        return <LoadingView height="200px"/>
    }

    return (
        <div className='w3-padding-8 w3-list w3-list-bordered'>
            {
                payment_transactions.length
                ?   payment_transactions.map(
                        (payment_transaction, i) =>
                            <PaymentTransactionView key={i} payment_transaction={payment_transaction}/>
                    )
                :   <div className="w3-center w3-text-grey w3-padding-16">No payment transactions</div>
            }
        </div>
    );
}


function PaymentTransactionView({payment_transaction}){
    return (
        <div className="w3-row w3-margin-bottom-8 w3-padding-8 w3-border-bottom">
            <div className="w3-flex-row w3-flex-vcenter w3-margin-bottom-16 w3-row">
                <div className="title w3-capitalize">
                    {payment_transaction.status} payment for <span className="w3-bold">{payment_transaction.payment_for}</span>
                </div>
                <div className="w3-margin-left-auto w3-large w3-bold">
                    {payment_transaction.payment_amount}
                </div>
            </div>
            <div className="w3-row w3-text-grey w3-tiny">
                <div className="w3-margin-right w3-left" style={{"color": getRandomColor(payment_transaction.payment_instrument)}}>
                    <span className="fas fa-dot-circle"></span> {payment_transaction.payment_instrument}
                </div>
                <DateView millis={payment_transaction.updated_at} />
            </div>
            <div className="w3-row w3-tiny">
                {   
                    payment_transaction.payment_instrument_id
                    ?   <div>Instrument: {payment_transaction.payment_instrument_id}</div>
                    :   null
                }
                {objToEl(payment_transaction.payment_data)}
            </div>
        </div>
    );
}

const OrderCta = ({order, setOrder, delivery_route}) => {
    const [cart, setCart] = useBroadcastedState("cart_updated", {});
    const to_pay_amount = order.total_amount - order.total_paid;

    const confirmChange = () => {
        var popup = Popup.show(
            'Apply Modification',
            <ConfirmOrderChange
                order={order}
                onConfirm={
                    (modify_reason_data) => {
                        applyModifications(modify_reason_data);
                        popup.close();
                    }
                }
            />
        );
    };

    const applyModifications = (modify_reason_data) => {
        const url = `/api/delivery/modify/${delivery_route._id}/${order._id}`
        var ui_block = Popup.blockUi("Wait...");
        const data = {
            cart_id: cart._id,
            ...(modify_reason_data || {})
        };
        axios.post(url, data).then(
            (resp) => {
                if(resp.data.errors){
                    GenericException.showPopup(resp.data.errors);
                }
                if(resp.data.order){
                    setCart({})
                    setOrder(resp.data.order);
                    if(resp.data.order?.third_party_order_id){
                        var ui_block = Popup.blockUi(`Updating order at ${resp.data.order?.third_party_order_id.split("_")[0]}, please wait...`);
                        setTimeout(function() {
                            ui_block.remove()
                            fetchOrder()
                        }, 10*1000) // 10 sec delay to fetch updated order
                    }
                }
            }
        ).finally(
            () => ui_block.remove()
        )
    }

    const fetchOrder = () => {
        var ui_block = Popup.blockUi("Refreshing, please wait...", {"remove_others": true}); 
        axios.post(
            `/api/edit/order/${delivery_route._id}/${order._id}`,
            { "action":"view"}
        ).then(
            (resp) => {
                if(resp.data.errors){
                    GenericException.showPopup(resp.data.errors);
                }
                if(resp.data.order){
                    setOrder(resp.data.order);                
                }
            }
        ).finally(
            () => ui_block.remove()
        )
    }

    const viewPayments = (evt) => {
        Popup.show(
            "Payment Transactions",
            <PaymentTransactions order={order} />,
        )
    }

    return (
        <div className='tw-bg-white tw-absolute tw-border-t tw-w-full tw-bottom-0 tw-drop-shadow'>
            <div className='hflex tw-p-4 tw-gap-4'>
                {
                    cart._id
                    ?   <>
                            <button className='tw-border tw-text-gray-600 tw-text-sm tw-px-4 tw-py-2 tw-rounded tw-flex-1' onClick={() => setCart({})}>
                                Cancel
                            </button>
                            <button className='tw-bg-secondary tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded tw-flex-1 tw-whitespace-nowrap' onClick={confirmChange}>
                                Apply modification
                            </button>
                        </>
                    :   <>
                            {
                                order.status !== ORDER_STATUS_DELIVERED
                                ?   <button className={`${to_pay_amount > 100 ? 'tw-bg-secondary' : 'tw-bg-primary-dark'} tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded`}
                                        onClick={
                                            () => {
                                                var popup = Popup.slideUp(
                                                    <PaymentOptions
                                                        delivery_route={delivery_route} 
                                                        order={order}
                                                        cp_ids={[]} // Keeping it empty for now as the use-case to collect payments for selected products might comeback.
                                                        afterUpdate={(order) => { popup.close(); setOrder(order);}}
                                                    />,
                                                    {"title": "Last mile payments"}
                                                )
                                            }
                                        }
                                    >
                                        {to_pay_amount > 100 ? "Collect Payment" : "Complete Delivery"}
                                    </button>
                                :   <div></div>
                            }
                            <div className='tw-grid tw-grid-cols-2' onClick={viewPayments}>                                
                                <div className='tw-text-sm tw-font-bold text tw-text-right tw-whitespace-nowrap'>
                                    {
                                    order.discounts_total > 0 && <div>Total Discount:</div>
                                    }
                                    <div className=' tw-mt-2'>Total Amount:</div>
                                    <div className='tw-mt-2'>To Pay:</div>
                                </div>
                                <div className='tw-px-4 tw-text-sm tw-font-bold tw-whitespace-nowrap' >
                                    {
                                        order.discounts_total > 0 
                                        &&  <div className='tw-text-green-600'>
                                                {getDisplayPrice(delivery_route.currency, order.discounts_total)}
                                            </div>
                                    }
                                    <div className='tw-mt-2'>{getDisplayPrice(delivery_route.currency, order.total_amount)}</div>
                                    {
                                        order.total_amount - order.total_paid > 10
                                        ?   <div className='tw-mt-2 tw-text-red-600'>{getDisplayPrice(delivery_route.currency, to_pay_amount)}</div>
                                        :   <div className='tw-mt-2 tw-text-green-600'>Paid</div>
                                    }
                                </div>
                            </div>
                        </>
                }
                
            </div>
        </div>
    )
}

export default OrderCta