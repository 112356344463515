import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import { broadcaster, useBroadcastedState, useLocalStorageState } from 'base/utils/events';
import { LoadUserChats } from 'base/ui/chat'

import "./css/app.css"
import WalletMoneyIcon from "./images/wallet_money.png"
import { LocalStorageCache, round } from 'base/utils';
import { EmptyView } from 'base/ui/status';
import { Popup } from 'base/ui/popups';
import { IS_MOBILE, IS_DEV } from 'base/constants';
import QRCode from "react-qr-code";
import HeaderNav from './components/layout/HeaderNav';
import { WalletLedgerEntries } from 'base/payments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { GenericException } from 'base/ui/errors';
import { FileUploader } from 'base/file_uploader';
import LoginPopup from './components/features/login/LoginPopup';
import LoginForm from './components/features/login/LoginForm';

const cache = new LocalStorageCache("cache_v3");

const useLogin = () => {
  const [user, setUser] = useBroadcastedState("user_updated");
  const [is_page_visible] = useBroadcastedState("is_page_visible", true);
  const [login_code, setLoginCode] = useLocalStorageState("login_code");
  const [wa_login_link, setWaLoginLink] = useState(null);

  /* check if user logged in when returned to page */
  useEffect(
    () => {
      if(is_page_visible && login_code){
          /* user came back to this page */
          checkUserLoginActivated(login_code);
      }
    }, [is_page_visible, login_code]
  )

  const getWaLoginData = () => {
    let params = {};
    if(!window.location.pathname.includes("/admin")){
      params["delivery_route_id"] = cache.get("selected_delivery_route_id");
    }
    axios.get(
      `/api/user/login/`, {"params": params}
    ).then((resp) => {
      setLoginCode(resp.data.login_code);
      setWaLoginLink(resp.data.wa_login_link);
    })
  }

  const checkUserLoginActivated = () => {
    if(!login_code) return;
    axios.get(`/api/user/login/${login_code}`).then((resp) => {
      if(resp.data.user){
        setUser(resp.data.user);
        setLoginCode("");
      } else if(resp.data.expired){
        setLoginCode("");
      }
    });
  }

  return {user, wa_login_link, getWaLoginData, checkUserLoginActivated}
}


function UserProfile({user:_user}){
  const [user, setUser] = useBroadcastedState("user_updated", _user);
  const [is_edit, setEdit] = useState(false);
  const ctx = useRef({}).current;

  useEffect(
      () => {
          axios.get("/api/user/profile").then(
              (resp) => {
                resp.data.user && setUser(resp.data.user);
              }
          )
      },
      []
  );

  const doLogout = (evt) => {
    axios.post("/api/logout", {"action": "logout"}).then(
        (resp) => {
            if(resp.data.loggedout){
                setUser(null);
            }
        }
    )
  }

  const openEdit = () => {
    ctx.name = user.name;
    setEdit(true);
  }

  const editProfile = () => {
    if (ctx.in_progress) return;
    ctx.in_progress = true;
    const payload = {name: ctx.name};
    if (ctx.images) {
      payload.images = ctx.images}
    ;
    axios.post('/api/user/update', payload)
      .then((resp) => {
        if(resp.data.errors){
          Popup.show("Errors", <GenericException ex={resp.data.errors} />)
          return;
        }
        if (resp.data.user) {
          setUser({...user, ...resp.data.user});
        }
        setEdit(false);
      }).catch((err) => {
        Popup.show("Errors", <GenericException ex={err} />)
      }).finally(
        () => ctx.in_progress = false
      );
  }


  if(!user){
    return <EmptyView title="You have been logged out" height="100px" />
  }

  return (
    is_edit
      ? 
        <div className='tw-p-4'>
          <div className='w3-text-grey'>Name of the customer</div>
          <input type="text"  className='w3-input tw-mb-4'
              placeholder='Enter Name here'
              defaultValue={user.name || ''}
              required={true}
              onChange={(evt) => ctx.name = evt.target.value}
            />
            
            <div className='w3-text-grey tw-mb-1'>Profile Image</div>
            <FileUploader files={user.image ? [user.image] : null}
              onFilesUpdated={(files) => files ? ctx.images = files.map(f => f.url) : null} 
            />
            <div className='w3-list-horizontal-8 w3-padding-4 tw-mt-4'>
              <div className='w3-button w3-red' onClick={() => setEdit(false)}>
                  Cancel
              </div>
              <div className='w3-button w3-black' onClick={editProfile}>
                  Update
              </div>
            </div>
        </div>
      : <div className='w3-row w3-padding'>
          <div className='tw-flex tw-justify-between tw-items-start'>
            <div>
              {
                  user.image
                  ?   <div className='w3-center'>
                          <img src={user.image} alt="Profile" className="w3-image" height="100px"/>
                      </div>
                  :   null
              }
              <div className='w3-bold w3-large'>{user.name}</div>
              <div className='w3-margin-bottom'>
                {user.phone_number}
                {
                  user.data.wa_number
                  ? <div className='w3-text-grey'>Whatsapp number: {user.data.wa_number}</div>
                  : null
                }
              </div>
            </div>
            <button className='tw-border tw-border-secondary tw-rounded tw-text-secondary tw-text-xs tw-px-4 tw-py-2 tw-mb-4'
              onClick={openEdit}
            >
              <FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Edit Profile
            </button>
          </div>
            <hr/>
            <br/>
            {
              user.wallets?.length
              ? <div>
                  <div>Your Wallets</div>
                  <div className='w3-list-16 w3-list-bordered'>
                    {
                        user.wallets?.map(
                            (uw) => (
                                <div className='w3-row w3-padding-topbottom-8' key={`${uw.user_id}_${uw.wallet_for}_${uw.wallet_currency}`}>
                                    <div className='w3-col s7'>
                                      <div>{uw.wallet_for_str}</div>
                                      <div>
                                        {
                                            uw.minimum_wallet_amount 
                                            ?   <div>
                                                    <div className='w3-text-grey w3-small'>Credit available</div>
                                                    <div className='w3-text-grey w3-small w3-text-green w3-bold'>
                                                        {-round(uw.minimum_wallet_amount)} {uw.wallet_currency}
                                                    </div>                          
                                                </div>
                                            :   null
                                        }
                                      </div>
                                    </div>
                                    <div className='w3-col s5'>
                                        <div className='w3-bold w3-flex' onClick={
                                          () => Popup.show("Ledger Entries", <WalletLedgerEntries wallet={uw} />)
                                        }>
                                          <img src={WalletMoneyIcon} alt="wallet" width="20px"/>&nbsp;&nbsp;
                                          {round(uw.wallet_amount)} {uw.wallet_currency}
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                  </div>
                </div>  
              : null
            }
            <div className='w3-flex-row-wrap w3-list-horizontal-16'>
              <div className='w3-small w3-button w3-red w3-round-xlarge' onClick={doLogout}>Logout</div>
            </div>
        </div>
  )
}


function UserActionIcons({delivery_route_id, className}){
    const [show_login, setShowLogin] = useState(false);
    const {user, wa_login_link, getWaLoginData} = useLogin();

    useEffect(() => {
      if (show_login && IS_MOBILE && wa_login_link) {
        setShowLogin(false);
        window.location.href = wa_login_link
      }
    }, [wa_login_link, show_login])
  
    /* click handler to login, checked on mobile / non mobile differently */
    const doLogin = useCallback(
      () => {
        if(user){
          return;
        }
        setShowLogin(true);
        if (IS_MOBILE && !wa_login_link) {
          getWaLoginData();
        }
      },
      [user]
    );
  
    const showUserProfile = () => {
      Popup.show("User Profile", <UserProfile user={user} />);
    }
  
    const showChatSessions = (evt) => {
      var popup = Popup.showContextMenu(
        evt.target,
        <div style={{"height": "400px", "width": "500px"}}>
          <LoadUserChats onLoad={() => popup.rerender()} />
        </div>
      )
    }
  
    /* add a global listener to initiate login from this component */
    useEffect(
      () => {
        broadcaster.add_event_listener("do_login", doLogin);
        return () => broadcaster.remove_event_listener("do_login", doLogin)
      }, [doLogin]
    );
  
    useEffect(() => {user && setShowLogin(false)}, [user]);
  
    return (
      <>
        <HeaderNav 
          className={className}
          user={user} 
          onLogin={doLogin} 
          onShowChatSession={showChatSessions}
          onShowUserProfile={showUserProfile}
        />
        <LoginPopup onClose={() => setShowLogin(false)} show={show_login && !IS_MOBILE}>
          <LoginForm />
        </LoginPopup>
      </>
    );
}

export {UserProfile, UserActionIcons, useLogin, cache}
