import React from "react";
import { TemplatesPopUp } from "./TemplatesPopUp";
import { Popup } from "base/ui/popups";
import CreateBroadcast from "./CreateBroadcast";

/**
 * Extracts specific data from a template object and returns it in a structured format.
 *
 * @param {Object} template - The template object containing components.
 * @returns {Object} - An object containing extracted data from the template.
 * @property {string} [message.image] - The file URL for image, video, or document formats.
 * @property {string} [message.body] - The text content of the body component.
 * @property {string} [message.header_text] - The text content of the header component.
 * @property {string} [message.footer] - The text content of the footer component.
 * @property {Array} [message.buttons] - The buttons in the buttons component.
 */
function getTemplateBody(template) {
	let message = {};
	template?.components.forEach((c) => {
		if (c.type.toLowerCase() === "header") {
			if (c.parameters) {
				c.parameters.forEach((p) => {
					if (!p) return;
					if (p.type === "image") {
						message.image = p.image.link;
					} else if (p.type === "video") {
						message.image = p.video.link;
					} else if (p.type === "document") {
						message.image = p.document.link;
					}
				});
			} else if (c.format === "IMAGE" || c.format === "VIDEO" || c.format === "DOCUMENT") {
				message.image = c.example?.header_handle?.[0];
			} else if (c.format === "TEXT") {
				message.header_text = c.text;
			}
		} else if (c.type.toLowerCase() === "body") {
			message.body = c.text;
		} else if (c.type.toLowerCase() === "footer") {
			message.footer = c.text;
		} else if (c.type.toLowerCase() === "buttons") {
			message.buttons = c.buttons;
		}
	});
	return message;
}

/**
 * Extracts variables from a given text string that follow the format "{{n}}" where n is a number.
 *
 * @function getBodyVariables
 * @param {string} text - The text string to extract variables from.
 * @returns {Array|undefined} - An array of extracted variables if found, or undefined if no variables are found.
 *
 * @example
 * getBodyVariables("Hello, {{1}}! Welcome to {{2}}.") // Returns: ["{{1}}", "{{2}}"]
 * getBodyVariables("No variables here.") // Returns: undefined
 */
function getBodyVariables(text) {
	if (!text) return undefined;
	return text.match(/\{\{([1-9]|[0-9][0-9])\}\}/g);
}

/**
 * Displays a popup to select a template for creating a broadcast.
 *
 * @function showSelectTemplatePopUp
 * @param {Object} org - The organization object containing the business number and ID.
 * @param {Array} selected_users_ui - An UI of selected users for the broadcast.
 * @param {Object} url_params - Additional URL parameters for the broadcast creation mostly search filters.
 *
 * @returns {undefined} - This function does not return a value.
 *
 * @example
 * showSelectTemplatePopUp(org, selected_users_ui, url_params);
 */
const showSelectTemplatePopUp = (org, selected_users_ui, url_params) => {
	const popup = Popup.show(
		"Select Template",
		<TemplatesPopUp
			wa_business_number={org.wa_business_number}
			org_id={org._id}
			onTemplateSelected={(template) => {
				popup.close();
				showCreateBroadcastPopUp(org, template, selected_users_ui, url_params);
			}}
		/>,
	);
};

/**
 * Displays a popup to create a broadcast using a selected template.
 *
 * @function showCreateBroadcastPopUp
 * @param {Object} org - The organization object containing the business number and ID.
 * @param {Object} template - The selected template object for creating the broadcast.
 * @param {Array} selected_users_ui - An UI of selected users for the broadcast.
 * @param {Object} url_params - Additional URL parameters for the broadcast creation mostly search filters.
 *
 * @returns {undefined} - This function does not return a value.
 *
 * @example
 * showCreateBroadcastPopUp(org, template, selected_users_ui, url_params);
 */
const showCreateBroadcastPopUp = (org, template, selected_users_ui, url_params) => {
	const popup = Popup.show(
		"Create Broadcast",
		<CreateBroadcast
			org={org}
			template={template}
			selected_users_ui={selected_users_ui}
			url_params={url_params}
			onSubmit={() => {
				popup.close();
			}}
		/>,
	);
};

export { getTemplateBody, getBodyVariables, showSelectTemplatePopUp };
