import React from 'react';
import { SuggestedField } from 'base/ui/suggested_field';

function SelectBuyForUser({delivery_route_id, buying_for_user, onBuyForUserSelect, onAddCustomer, className}){
    return (
        <div className='tw-p-4'>
            <div className='tw-my-4'>
                <SuggestedField
                    className="w3-margin-bottom-4"
                    props={{
                        "endpoint": `/api/admin/users/${delivery_route_id}?action=search`,
                        "results_key_path": "users",
                        "title_format": "{title}{name}",
                        "description_format": "{description}",
                        "image_key_path": "image",
                        "max_selections": 1,
                        "placeholder": "Search for a Customer",
                    }}
                    selected={buying_for_user && [buying_for_user]}
                    input_className={className || 'tw-border tw-border-gray-300 tw-px-4 tw-py-3 tw-rounded tw-w-full'}
                    onSelect={
                        (selected_users) => {
                            let buying_for_user = selected_users?.[0];
                            onBuyForUserSelect(buying_for_user); //could be null
                        }
                    }
                />
            </div>
            {
                onAddCustomer
                    ? <div>
                        <div className="tw-text-sm tw-flex tw-gap-3 tw-justify-center tw-my-3">
                            <div className="tw-border-t tw-border-landing-black tw-w-[50px] tw-mt-2 md:tw-mt-3" /> OR <div className="tw-border-t tw-border-landing-black tw-w-[50px] tw-mt-2 md:tw-mt-3" />
                        </div>
                        <div className="tw-flex tw-justify-center tw-mb-4">
                            <button className='tw-mt-2 tw-border tw-border-secondary-lighter tw-text-secondary-lighter tw-rounded tw-px-3 tw-py-1.5'
                                onClick={onAddCustomer}
                            >
                                + Add New Customer
                            </button>
                        </div>
                    </div>
                    : null
            }            
        </div>
    )
}

export default SelectBuyForUser;