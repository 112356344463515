import React, { useRef, useState, useEffect } from "react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clipboard from "base/utils/clipboard";
import { Popup } from "../../../base/ui/popups";
import { useBroadcastedState } from "../../../base/utils/events";
import { FloatingLabelInput, FloatingLabelSelect } from "../../../components/ui/FloatingLabel";
import WhatsappIcon from "../../../images/whatsapp.svg";
import { LoadingOverlay } from "../../../base/ui/status";
import sukhibaLogo from "../../../images/sukhiba_logo.png";
import sukhibaConnectLogo from "../../../images/ic_logo_sukhiba.svg";
import CHAT_DEFAULT_BG_IMAGE from "base/images/chat_bg.jpg";
import FullSukhibaLogo from "../../../images/ic_logo_sukhiba.svg";
import EventTracker from "../../../eventsTracker";
import { isValidPhone } from "base/utils/common";

const button_sizes = ["regular", "medium", "large"];
const button_positions = ["left", "right"];

function WhatsAppButton() {
	const [is_loading, setIsLoading] = useState(false);
	const ctx = useRef({}).current;
	const [screen] = useBroadcastedState("tw_screen_size");

	const [button_name, setButtonName] = useState("Contact Us");
	const [button_size, setButtonSize] = useState("medium");
	const [button_position, setButtonPosition] = useState("right");
	const [brand_name, setBrandName] = useState("Sukhiba");
	const [brand_subtitle, setBrandSubtitle] = useState("Business Account");
	const [brand_phone_number, setBrandPhoneNumber] = useState("");
	const [brand_logo, setBrandLogo] = useState("");
	const [welcome_message, setWelcomeMessage] = useState("Hi there 👋");
	const [prefilled_message, setPreFilledMessage] = useState("Hi, I want to know more!");
	const [excluded_urls, setExcludedUrls] = useState([]);
	const [wa_button_script, setWAButtonScript] = useState();
	const [cta, setCTA] = useState("Start Chat");

	function ButtonPreview() {
		return (
			<div>
				<div className="tw-border tw-rounded-xl tw-w-[360px] tw-text-xs tw-pb-2">
					<FontAwesomeIcon className="tw-float-right tw-m-4" icon={faX} color="black" />

					<div className="tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-white tw-text-sm">
						<div className="tw-rounded-full tw-grid tw-content-around tw-bg-gray-300 tw-w-10 tw-h-10">
							<img src={brand_logo || sukhibaLogo} className="tw-rounded-full" />
						</div>
						<div className="tw-ml-2 ">
							<p className="tw-font-bold tw-text-black tw-text-sm">{brand_name}</p>
							<p className="tw-text-gray-400">{brand_subtitle}</p>
						</div>
					</div>
					<div
						className="tw-h-[300px] tw-py-4"
						style={{ backgroundImage: "url(" + CHAT_DEFAULT_BG_IMAGE + ")", backgroundSize: "cover" }}
					>
						<div className="tw-bg-white tw-p-2 tw-text-black tw-rounded-xl tw-w-[70%] tw-m-2">
							<div className="tw-font-semibold">{brand_name}</div>
							<div className="tw-mt-2">{welcome_message}</div>
						</div>
						<div className="tw-bg-[#DCF7C5] tw-px-2 tw-py-4 tw-text-black tw-rounded-xl tw-w-[70%] tw-float-right tw-m-2">
							<div className="">{prefilled_message}</div>
						</div>
					</div>
					<div className="tw-mt-2 tw-px-4 tw-bg-white">
						<button className="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-rounded-full tw-w-full tw-my-2 tw-bg-[#27D466] tw-text-white tw-py-3.5">
							<img src={WhatsappIcon} width={18} />
							<div className="tw-text-md"> {cta}</div>
						</button>
						<div className="tw-text-xxs tw-text-black-400 tw-mr-2 tw-flex tw-justify-center">
							Powered by <img src={sukhibaConnectLogo} className="tw-ml-1 tw-w-[60px]" />{" "}
						</div>
					</div>
				</div>
				<div className={`tw-mt-2 tw-w-max ${button_position === "right" ? "tw-float-right" : ""}`}>
					<button
						className={`tw-flex tw-items-center tw-justify-center tw-gap-1 tw-rounded-full tw-px-8 tw-my-2 tw-bg-[#27D466] tw-text-white ${button_size === "medium" ? "tw-py-2.5" : button_size === "regular" ? "tw-py-1.5" : "tw-py-3.5"}`}
					>
						<img src={WhatsappIcon} width={18} />
						<div className="tw-text-md"> {button_name}</div>
					</button>
					<div className="tw-text-xxs tw-text-black-400 tw-flex tw-justify-center tw-gap-1">
						Powered by <img src={sukhibaConnectLogo} className="tw-w-[60px]" />{" "}
					</div>
				</div>
			</div>
		);
	}

	function validateAndNext() {
		const errors = [];
		if (!button_name?.trim()) {
			errors.push("Missing Button Name");
		}
		if (!brand_name?.trim()) {
			errors.push("Missing Brand Name");
		}
		if (!brand_subtitle?.trim()) {
			errors.push("Missing Brand Subtitle");
		}
		if (!isValidPhone(brand_phone_number)) {
			errors.push("Missing Valid Phone Number");
		}
		if (!welcome_message?.trim()) {
			errors.push("Missing Welcome Message");
		}
		if (!prefilled_message?.trim()) {
			errors.push("Missing Pre-Filled Message");
		}

		if (errors.length) {
			alert(errors.join(", "));
			return;
		} else {
			EventTracker.track("Whatsapp_Button_Generator_Details", {
				button_name,
				brand_logo,
				brand_name,
				brand_subtitle,
				button_size,
				button_position,
				cta,
				brand_phone_number,
				welcome_message,
				prefilled_message,
			});
			const script = `<script
            src='https://sukhiba.com/WhatsappWidget.js'
            async
            onLoad="whatsappButton({
            buttonName:'${button_name}',
            buttonIconSize: '22',
            brandImageUrl:'${brand_logo}',
            brandName:'${brand_name}',
            brandSubtitleText:'${brand_subtitle}',
            buttonSize:'${button_size}',
            buttonPosition:'${button_position}',
            callToAction:'${cta}',
            phoneNumber:'${brand_phone_number}',
            welcomeMessage:'${welcome_message}',
            prefillMessage:'${prefilled_message}',
            })"
            >
        </script>`;
			setWAButtonScript(script);
		}
	}

	useEffect(() => {
		if (wa_button_script) {
			document.body.scrollTop += 400;
		}
	}, [wa_button_script]);

	return (
		<div className="font-inter !tw-font-light max-md:tw-p-4 ">
			<div
				className="tw-sticky tw-ml-4 md:tw-ml-20 tw-mt-8 tw-cursor-pointer "
				onClick={() => window.open("https://sukhiba.com/home")}
			>
				<img src={FullSukhibaLogo} className="tw-h-[33px] max-md:tw-mb-6" />
			</div>
			<div
				className="tw-grid md:tw-justify-items-center tw-bg-white"
				// style={{ backgroundImage: 'url(' + bgPage1 + ')', backgroundSize: 'cover' }}
			>
				<div className="tw-text-landing-black !tw-font-semibold tw-text-center lg:tw-whitespace-pre tw-py-4">
					<div className="tw-text-[30px] lg:tw-text-[38px] xl:tw-text-[48px] 2xl:tw-text-[56px] tw-leading-tight tw-tracking-wide">
						Generate
						<span className="tw-text-landing-green">{` Whatsapp Chat\nButton `}</span>for your website
						<div className="tw-text-gray-600 tw-mt-4 tw-text-sm tw-font-light">
							{`Plug in this free WhatsApp widget and keep the conversation going with your customers even if they leave your website.\nGenerate your own website WhatsApp button in 3 simple steps.`}
						</div>
					</div>
				</div>

				{is_loading ? <LoadingOverlay title={"Please wait"} /> : null}

				<div className="tw-relative tw-grid tw-mt-8 tw-grid-cols-2 max-md:tw-grid-cols-1 max-md:tw-w-[100%] tw-justify-center md:tw-divide-x">
					<div
						className={`tw-grid tw-grid-cols-1 md:tw-justify-items-center md:tw-justify-items-end`}
						ref={(e) => {
							e ? (ctx.grid_box = { width: e.offsetWidth, height: e.offsetHeight }) : null;
						}}
					>
						<div className="tw-px-4 md:tw-px-6 tw-pb-8 md:tw-w-[80%] tw-float-right">
							<div className="tw-text-md tw-text-gray-900 tw-font-semibold">Chat button settings</div>
							<div className="tw-relative tw-mt-4">
								<FloatingLabelInput
									placeholder={"Button Name"}
									maxLength={30}
									value={button_name}
									onChange={(e) => setButtonName(e.target.value)}
								/>
								<div className="tw-absolute tw-top-0 tw-right-0 tw-text-xxs tw-text-gray tw-m-2">
									{button_name.length}/30
								</div>
							</div>
							<div className="tw-flex tw-gap-4">
								<div className="tw-relative tw-mt-4 tw-flex-1">
									<FloatingLabelSelect
										placeholder={"Select Button Size"}
										defaultValue={button_size}
										onChange={(e) => {
											setButtonSize(e.target.value);
										}}
									>
										{button_sizes.map((p) => (
											<option value={p} key={p}>
												{p.toLocaleUpperCase()}
											</option>
										))}
									</FloatingLabelSelect>
								</div>
								<div className="tw-relative tw-mt-4 tw-flex-1">
									<FloatingLabelSelect
										placeholder={"Select Position"}
										defaultValue={button_position}
										onChange={(e) => {
											setButtonPosition(e.target.value);
										}}
									>
										{button_positions.map((p) => (
											<option value={p} key={p}>
												{p.toLocaleUpperCase()}
											</option>
										))}
									</FloatingLabelSelect>
								</div>
							</div>
							<div className="tw-text-md tw-mt-4 tw-text-gray-900 tw-font-semibold">Widget settings</div>
							<div className="tw-relative tw-mt-4">
								<FloatingLabelInput
									placeholder={"Brand Name"}
									maxLength={40}
									defaultValue={brand_name}
									onChange={(e) => setBrandName(e.target.value)}
								/>
								<div className="tw-absolute tw-top-0 tw-right-0 tw-text-xxs tw-text-gray tw-m-2">
									{brand_name.length}/40
								</div>
							</div>
							<div className="tw-relative tw-mt-4">
								<FloatingLabelInput
									placeholder={"Brand Subtitle"}
									maxLength={120}
									defaultValue={brand_subtitle}
									onChange={(e) => setBrandSubtitle(e.target.value)}
								/>
								<div className="tw-absolute tw-top-0 tw-right-0 tw-text-xxs tw-text-gray tw-m-2">
									{brand_subtitle.length}/120
								</div>
							</div>
							<div className=" tw-my-4">
								<FloatingLabelInput
									placeholder={"Phone Number*"}
									defaultValue={brand_phone_number}
									onChange={(e) => setBrandPhoneNumber(e.target.value)}
								/>
							</div>
							<div className="tw-relative tw-my-4">
								<FloatingLabelInput
									placeholder={"Brand Image Url"}
									defaultValue={brand_logo}
									onChange={(e) => setBrandLogo(e.target.value)}
								/>
								<div className="tw-float-right tw-text-xxs tw-text-gray tw-my-1">
									Upload image URLs ending with jpeg or png
								</div>
							</div>
							<div className="tw-relative tw-mt-8">
								<FloatingLabelInput
									placeholder={"Welcome Message"}
									defaultValue={welcome_message}
									onChange={(e) => setWelcomeMessage(e.target.value)}
								/>
								<div className="tw-absolute tw-top-0 tw-right-0 tw-text-xxs tw-text-gray tw-m-2">
									{welcome_message.length}
								</div>
							</div>
							<div className="tw-relative tw-mt-4">
								<FloatingLabelInput
									placeholder={"Pre-Filled Message"}
									defaultValue={prefilled_message}
									onChange={(e) => setPreFilledMessage(e.target.value)}
								/>
								<div className="tw-absolute tw-top-0 tw-right-0 tw-text-xxs tw-text-gray tw-m-2">
									{prefilled_message.length}
								</div>
							</div>
							<div className="tw-relative tw-mt-4">
								<FloatingLabelInput
									placeholder={"Call-To-Action"}
									defaultValue={cta}
									maxLength={30}
									onChange={(e) => setCTA(e.target.value)}
								/>
								<div className="tw-absolute tw-top-0 tw-right-0 tw-text-xxs tw-text-gray tw-m-2">{cta.length}/30</div>
							</div>
							{/* <div className="tw-text-md tw-mt-4 tw-text-gray-900 tw-font-semibold">
                            Advance settings
                        </div>
                        <div className='tw-mt-4'>
                            <div className=''>
                                <FloatingLabelInput
                                    placeholder={'Include Url Paths'}
                                    onChange={(e) => Array.from(e.target.value)} />
                            </div>
                            <div className='tw-text-right tw-text-xxs tw-text-gray tw-m-2'>Add multiple urls to exclude this WhatsApp Widget, separated by comma(,)</div>
                        </div> */}
							<button
								className="tw-rounded-full tw-w-full tw-mt-4 tw-bg-landing-green tw-text-white tw-px-9 tw-py-3.5"
								onClick={validateAndNext}
							>
								Generate Code
							</button>
							{wa_button_script ? (
								<div className="tw-mt-8" id="wa-script">
									<div className="tw-text-gray-600 tw-mt-4 tw-text-sm tw-font-light">
										{" "}
										{`**Copy this code and paste before the </body> tag on your website.`}
									</div>

									<textarea
										className="tw-mt-4 tw-border-1 tw-p-2 tw-w-full tw-bg-white tw-border tw-rounded"
										disabled={true}
										style={{ minHeight: "9rem" }}
										value={wa_button_script}
									/>

									<button
										className="tw-rounded-full tw-border tw-border-landing-green tw-w-full tw-mt-4 tw-text-landing-green tw-px-9 tw-py-3.5"
										onClick={() => {
											{
												Clipboard.copy(wa_button_script);
												Popup.toast("Copied to clipboard");
											}
										}}
									>
										Copy Code to Clipboard
									</button>
								</div>
							) : null}
						</div>
					</div>

					{/* Button Preview */}
					{screen.md ? (
						<div className="tw-px-4 md:tw-px-6">
							<div className="tw-text-md tw-mb-2 tw-text-gray-900 tw-font-semibold">Button Preview</div>
							<div className="tw-m-6 tw-flex tw-justify-between tw-items-center">
								<ButtonPreview />
							</div>
						</div>
					) : null}
				</div>
			</div>
			<IntegrationSection />
		</div>
	);
}

function IntegrationSection() {
	const integrations = {
		woo: "Woo Commerce",
		shopify: "Shopify",
	};
	const _details = {
		shopify: {
			title:
				"To add a script to the body tag on your home page in Shopify, you will need to edit your theme's code. Here’s how to do it:",
			points: [
				"1. From your Shopify admin, go to Online Store > Themes.",
				"2. Find the theme you want to edit and click the ... button to open the actions menu, then select Edit code.",
				"3. In the Layout directory, click on theme.liquid.",
				`4. Locate the closing </body> tag in the code.`,
				"5. Above the closing </body> tag, paste your script code.",
				"6. Click Save to apply your changes.",
			],
		},
		woo: {
			title:
				"To add a script to the body tag on your home page in WooCommerce, you will need to add it to Code's Snippet. Here’s how to do it:",
			points: [
				"1. From your WooCommerce Admin, Go to Settings -> Header & Footer or Code Snippets -> Header and Footer",
				"2. Locate the Body Section",
				"3. Add the Script that you generated",
				"4. Click Save changes",
				"5. Go to the WooCommerce store & refresh the page to see the widget placed on the bottom right side of the store",
			],
		},
	};
	const [selected_tab, setSelectedTab] = useState("shopify");

	return (
		<div className="tw-text-landing-black tw-bg-[#FBF6EF] tw-grid tw-justify-center tw-p-8 lg:tw-p-16">
			<div className="tw-text-center tw-font-medium">
				<div className="tw-text-[18px] md:tw-text-[28px] tw-text-landing-green">Third-Party Integrations</div>
			</div>
			<div className="tw-flex tw-flex-wrap tw-mt-4 md:tw-mt-6 tw-justify-center tw-gap-2 md:tw-gap-4 tw-text-[12px] md:tw-text-[16.6px]">
				{Object.keys(integrations).map((feature, i) => {
					return (
						<div
							key={i}
							className={`tw-rounded-full tw-cursor-pointer tw-px-4 xl:tw-px-8 tw-py-2 ${feature === selected_tab ? "" : "hover:tw-border hover:tw-border-landing-green hover:tw-text-landing-green"} ${feature === selected_tab ? "tw-bg-landing-green tw-text-white" : "tw-bg-white tw-border tw-border-landing-black"}`}
							onClick={() => {
								setSelectedTab(feature);
							}}
						>
							{integrations[feature]}
						</div>
					);
				})}
			</div>
			<div
				className={`md:tw-flex md:tw-mx-8 tw-my-8 tw-justify-center max-md:tw-border tw-border-landing-gray max-md:tw-rounded-2xl `}
			>
				<div className="tw-p-6 tw-bg-white max-md:tw-rounded-t-2xl md:tw-rounded-l-2xl">
					{_details[selected_tab].title ? (
						<p className="tw-text-landing-green tw-font-medium tw-mb-2 tw-text-xl">{_details[selected_tab].title}</p>
					) : null}
					<ul className="tw-mt-6 tw-list-none tw-list">
						{_details[selected_tab].points.map((point, j) => (
							<div key={j} className="tw-text-[#FFA800] tw-flex tw-gap-2 tw-mt-2">
								<div className="!tw-font-medium tw-text-black">
									<div className="tw-text-[#666666] tw-font-light tw-text-sm">{point}</div>
								</div>
							</div>
						))}
					</ul>
					<div className="tw-text-center tw-italic tw-mt-4 tw-text-xs">
						*Make sure to test your home page to ensure the script is functioning as expected.
					</div>
				</div>
			</div>
		</div>
	);
}

export default WhatsAppButton;
