import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GenericException } from "base/ui/errors";
import { Popup } from "base/ui/popups";
import { round } from "base/utils";
import { WalletLedgerEntries } from "base/payments";
import { ChatSession } from "base/ui/chat";
import { getStartOfDay, loadGoogleMapsScript } from "base/utils/common";
import { geoDistance } from "base/ui/location";
import { CHECKIN_STATUS } from "../../../../useStaffLocation";
import { measureDuration, millisToDateString } from "base/ui/date";
import { MILLIS_IN_A_DAY } from "base/constants";
import { getByIds } from "base/get_by_ids";
import { useCurrentUser } from "base/app";
import { UserBadge } from "../../../ui/commonUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCheckCircle, faCircleExclamation, faPencil } from "@fortawesome/free-solid-svg-icons";
import { LoadingOverlay } from "base/ui/status";
import { INBOX_MESSAGE_TYPE_ROUTE_PLAN, INBOX_MESSAGE_TYPE_STAFF_GPS } from "../../../../constants";
import { broadcaster } from "base/utils/events";
import ResponsivePopup from "../../../ui/ResponsivePopup";
import { DateView } from "base/ui/date";
import CustomersList from "../../customers/CustomersList";

const CUSTOME_STORE_RADIUS = 0.02; // Kms
const CHECKIN_MARKER = { background: "#0CB800", borderColor: "#098B00", glyphColor: "#098B00", scale: 0.6 };
const CHECKOUT_MARKER = { scale: 0.6 };
const ACTIVITY_MARKER = { background: "#F48400", borderColor: "#C96D00", glyphColor: "#C96D00", scale: 0.7 };
const POLYLINE = { geodesic: true, strokeColor: "#00B2F5", strokeOpacity: 1.0, strokeWeight: 2 };

function getApproxTimeSpent(gps_logs, i) {
	const prev = gps_logs
		.slice(i + 1)
		.find((log) => geoDistance(...gps_logs[i].data.lat_long, ...log.data.lat_long, "K") >= CUSTOME_STORE_RADIUS);
	const next = gps_logs
		.slice(0, i)
		.reverse()
		.find((log) => geoDistance(...gps_logs[i].data.lat_long, ...log.data.lat_long, "K") >= CUSTOME_STORE_RADIUS);
	if (prev && next) {
		return next.created_at - prev.created_at;
	}
	return null;
}

export function TrackStaffLocation({ delivery_route_id, staff, date: _date, hide_summary }) {
	const session_id = `staff_notes_${delivery_route_id}_${staff._id}`;
	const [gps_logs, setGpsLogs] = useState([]);
	const [planned_visits, setPlannedVisits] = useState([]);
	const [customer_visits, setCustomerVisits] = useState([]);
	const [date, setDate] = useState(_date || new Date());
	const [map, setMap] = useState(null);
	const [staff_activity, setStaffActivity] = useState(null);
	const [active_tab, setActiveTab] = useState(0);
	const ctx = useRef({}).current;
	const [is_loading, setLoading] = useState(false);
	const user = useCurrentUser();
	const [edit_route_plan, setEditRoutePlan] = useState(false);

	useEffect(() => {
		if (_date) {
			setDate(_date);
		}
	}, [_date]);

	const getMessages = () => {
		if (ctx.has_more === false || ctx.is_loading) return;
		setLoading(true);
		ctx.is_loading = true;
		const start_time = new Date(date).setUTCHours(0, 0, 0, 0);
		axios
			.post("/session/messages", {
				less_than_timestamp: ctx.next_less_than_timestamp || start_time + MILLIS_IN_A_DAY,
				greater_than_timestamp: start_time - 1,
				session_id: session_id,
			})
			.then((resp) => {
				if (resp.data.error_message) {
					return;
				}
				ctx.has_more = resp.data.has_more;
				/* GPS logs */
				const logs = resp.data.inbox_messages?.filter((m) => m._type === INBOX_MESSAGE_TYPE_STAFF_GPS) || [];
				ctx.gps_logs = [...(ctx.gps_logs || []), ...logs];
				setGpsLogs(ctx.gps_logs);

				/* Route Plan */
				const route_plan = resp.data.inbox_messages?.find((m) => m._type === INBOX_MESSAGE_TYPE_ROUTE_PLAN);
				if (route_plan?.data?.customer_ids) {
					getCustomerDetails(route_plan.data.customer_ids).then((data) => {
						const users = {};
						route_plan.data.customer_ids.forEach((user_id) => {
							users[user_id] = data.users[user_id];
						});
						setPlannedVisits(users);
					});
				}

				ctx.is_loading = false;
				ctx.next_less_than_timestamp = resp.data.next_less_than_timestamp;
				if (ctx.has_more) {
					getMessages();
				}
			})
			.finally(() => {
				ctx.is_loading = false;
				setLoading(false);
			});
	};

	const initializeMap = async () => {
		const { Map } = await window.google.maps.importLibrary("maps");
		const { PinElement, AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
		const map = new Map(document.getElementById("staff_map"), {
			center: { lat: -1.286, lng: 36.817 },
			zoom: 10,
			mapId: "7651a7a9552a860c",
			fullscreenControlOptions: {
				position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
			},
		});
		map.AdvancedMarkerElement = AdvancedMarkerElement;
		map.PinElement = PinElement;
		setMap(map);
	};

	const getCustomerDetails = (user_ids) => {
		return getByIds({ user_ids: user_ids });
	};

	useEffect(() => {
		if (map && !ctx.is_loading) {
			ctx.markers?.forEach((m) => m.setMap(null));
			ctx.markers = [];
			ctx.info_windows = {};
			ctx.line?.setMap(null);
			const activity = {
				total_distance: 0,
				total_time: 0,
				total_orders: 0,
				customer_visits: {},
			};

			if (gps_logs.length > 0) {
				const bounds = new window.google.maps.LatLngBounds();
				ctx.line = new window.google.maps.Polyline(POLYLINE);
				ctx.line.setMap(map);
				const line_path = ctx.line.getPath();

				// Calculating total time
				activity.total_time = gps_logs[0].created_at - gps_logs[gps_logs.length - 1].created_at;

				const check_in = gps_logs.findLastIndex((log) => log.data?.info?.status === CHECKIN_STATUS.CHECKED_IN);
				if (check_in >= 0) gps_logs[check_in].is_checkin = true;
				const check_out = gps_logs.findIndex((log) => log.data?.info?.status === CHECKIN_STATUS.NEED_CHECK_IN);
				if (check_out >= 0 && check_out < check_in) gps_logs[check_out].is_checkout = true;

				gps_logs.forEach((log, i) => {
					//Calculating Total distance
					activity.total_distance +=
						i === gps_logs.length - 1 ? 0 : geoDistance(...log.data.lat_long, ...gps_logs[i + 1].data.lat_long, "K");

					// Setting bounds, line,
					const lat_long = new window.google.maps.LatLng(...log.data.lat_long);
					bounds.extend(lat_long);
					line_path.push(lat_long);

					// Setting check-in and check-out marker
					if (log.is_checkin) {
						const info_content = `<div id="checkin_content" className="tw-p-4">Check-in at ${millisToDateString(log.created_at)}</div>`;
						const infowindow = new window.google.maps.InfoWindow({ content: info_content });
						const marker = new map.AdvancedMarkerElement({
							map,
							position: lat_long,
							content: new map.PinElement(CHECKIN_MARKER).element,
						});
						marker.addListener("click", () => {
							infowindow.open({ anchor: marker, map });
						});
						ctx.markers.push(marker);
					} else if (log.is_checkout) {
						const info_content = `<div id="checkout_content" className="tw-p-4">Check-out at ${millisToDateString(log.created_at)}</div>`;
						const infowindow = new window.google.maps.InfoWindow({ content: info_content });
						const marker = new map.AdvancedMarkerElement({
							map,
							position: lat_long,
							content: new map.PinElement(CHECKOUT_MARKER).element,
						});
						marker.addListener("click", () => {
							infowindow.open({ anchor: marker, map });
						});
						ctx.markers.push(marker);
					}

					// Setting activity markers, Tracking customer visits and time-spent
					if (log.data.info?.buying_for_user_id) {
						const { info } = log.data;
						const customer_visit = activity.customer_visits[info.buying_for_user_id] || {
							orders: [],
							lat_long,
							time_spent: getApproxTimeSpent(gps_logs, i),
						};
						if (info.order_id && !customer_visit.orders.includes(info.order_id)) {
							customer_visit.orders.push(info.order_id);
							activity.total_orders += 1;
						}
						if (info.is_customer_notes) {
							customer_visit.is_customer_notes = true;
						}
						activity.customer_visits[info.buying_for_user_id] = customer_visit;
					}
				});

				Object.entries(activity.customer_visits).forEach(([key, value], i) => {
					const { time, measure } = value.time_spent ? measureDuration(value.time_spent) : {};
					const info_content = `<div id="activity_${i}">Customer: <b>${key}</b><br />BFC Orders: <b>${value.orders.length}</b><br />Notes Taken: <b>${value.is_customer_notes ? "YES" : "NO"}</b><br/>Time Spent: <b>${time || "-"} ${measure || "-"}</b></div>`;
					const infowindow = new window.google.maps.InfoWindow({ content: info_content });
					const marker = new map.AdvancedMarkerElement({
						map,
						position: value.lat_long,
						content: new map.PinElement(ACTIVITY_MARKER).element,
					});
					marker.addListener("click", () => {
						infowindow.open({ anchor: marker, map });
					});
					ctx.markers.push(marker);
					ctx.info_windows[key] = infowindow;
				});

				getCustomerDetails(Object.keys(activity.customer_visits)).then((data) => {
					Object.keys(activity.customer_visits).forEach((key) => {
						activity.customer_visits[key].user = data.users[key];
					});
					setCustomerVisits({ ...activity.customer_visits });
					Object.entries(ctx.info_windows).forEach(([key, infowindow]) => {
						let { content } = infowindow;
						content = content.replace(key, data.users[key]?.name);
						infowindow.setContent(content);
					});
				});
				setStaffActivity(activity);
				map.fitBounds(bounds);
			}
		}
	}, [map, gps_logs]);

	const refetchMessages = () => {
		setStaffActivity(null);
		ctx.has_more = null;
		ctx.gps_logs = [];
		ctx.next_less_than_timestamp = null;
		setPlannedVisits([]);
		setCustomerVisits([]);
		getMessages();
	};

	useEffect(() => {
		if (staff && date) {
			refetchMessages();
		}
	}, [staff, session_id, date]);

	useEffect(() => {
		loadGoogleMapsScript("AIzaSyCSMLgYj7Uxz34Afcvclun1FslKy6ILtwI").then(() => {
			initializeMap();
		});
		broadcaster.add_event_listener("chat:message_sent", refetchMessages);
		return () => broadcaster.remove_event_listener("chat:message_sent", refetchMessages);
	}, []);

	const saveRoutePlan = () => {
		if (edit_route_plan?.length > 0) {
			setLoading(true);
			axios
				.post(`/api/admin/staff/route_plan/${delivery_route_id}`, {
					customer_ids: edit_route_plan,
					date: date.getTime(),
					staff_user_id: staff._id,
				})
				.then((resp) => {
					if (resp.data?.logged) {
						refetchMessages();
					}
					setEditRoutePlan(null);
				})
				.finally(() => setLoading(false));
		}
	};

	const openNotes = (user) => {
		ChatSession.open(`customer_notes_${delivery_route_id}_${user._id}`);
	};
	const buyForCustomer = (user) => {
		window.open(`/${delivery_route_id}?buying_for_user_id=${user._id}`, "_self");
	};
	const checkInCustomer = (user) => {
		var popup = Popup.show(
			"Customer Check In",
			<div className="tw-text-sm tw-p-4">
				To Check In, you must place an order for the customer or take any notes.
				<div className="tw-flex tw-mt-6 tw-gap-4 tw-justify-end">
					<button
						className="tw-border tw-border-secondary-lighter tw-text-secondary-lighter tw-px-3 tw-py-1.5 tw-text-xs tw-rounded tw-flex tw-items-center tw-gap-2"
						onClick={() => {
							popup.close();
							buyForCustomer(user);
						}}
					>
						<FontAwesomeIcon icon={faCartShopping} />
						Buy For Customer
					</button>
					<button
						className="tw-border tw-border-secondary-lighter tw-text-secondary-lighter tw-px-3 tw-py-1.5 tw-text-xs tw-rounded tw-flex tw-items-center tw-gap-2"
						onClick={() => {
							popup.close();
							openNotes(user);
						}}
					>
						<FontAwesomeIcon icon={faPencil} />
						Notes
					</button>
				</div>
			</div>,
		);
	};

	const { time: total_time, measure } = measureDuration(staff_activity?.total_time || 0);
	const additional_visits = Object.keys(customer_visits || {}).filter((user_id) => !planned_visits[user_id]);

	return (
		<>
			{is_loading ? <LoadingOverlay /> : null}
			{_date ? null : (
				<div className="lg:tw-hidden tw-bg-white tw-rounded tw-text-sm tw-py-2 tw-px-4 tw-border tw-w-fit tw-ml-4 tw-my-2">
					<input
						className="tw-outline-0"
						type="date"
						defaultValue={date ? date.toISOString().slice(0, 10) : ""}
						onChange={(evt) => setDate(new Date(evt.target.value))}
					/>
				</div>
			)}
			{/* Summary  */}
			{!hide_summary ? (
				<div className="tw-flex tw-items-center max-lg:tw-flex-wrap tw-py-2 tw-bg-primary-fade tw-bg-opacity-50 tw-pr-4">
					<div className="hflex tw-gap-4 lg:tw-border-r-2 tw-pr-4 tw-grow">
						<UserBadge user={staff} />
						{date.getTime() >= new Date().setHours(0, 0, 0, 0) ? (
							<button
								className="tw-bg-primary tw-text-white tw-rounded tw-px-3 tw-py-1.5 tw-text-xs lg:tw-text-sm"
								onClick={() => setEditRoutePlan(Object.keys(planned_visits || {}))}
							>
								{Object.keys(planned_visits || {}).length === 0 ? "+ Create" : "Edit"} Route Plan
							</button>
						) : null}
					</div>
					<div className="max-lg:tw-grid max-md:tw-grid-cols-3 md:tw-grid-cols-5 max-lg:tw-gap-y-4 lg:tw-flex">
						<div className="tw-px-4 tw-border-r">
							<b>{planned_visits ? Object.keys(planned_visits).length : 0}</b>
							<p className="tw-text-xs tw-text-gray-500">Planned Visits</p>
						</div>
						<div className="tw-px-4 tw-border-r">
							<b>{staff_activity ? Object.keys(staff_activity.customer_visits).length : 0}</b>
							<p className="tw-text-xs tw-text-gray-500">Visits Completed</p>
						</div>
						<div className="tw-px-4 tw-border-r">
							<b>{Number(staff_activity?.total_distance || 0).toFixed(2) || 0}</b>
							<p className="tw-text-xs tw-text-gray-500">Total Distance (KMs)</p>
						</div>
						<div className="tw-px-4 tw-border-r">
							<b>{total_time}</b>
							<p className="tw-text-xs tw-text-gray-500">Total Time ({measure})</p>
						</div>
						<div className="tw-px-4">
							<b>{staff_activity?.total_orders || 0}</b>
							<p className="tw-text-xs tw-text-gray-500">Orders</p>
						</div>
					</div>
				</div>
			) : null}
			<div className="md:tw-hidden tw-grid tw-grid-cols-2 tw-text-center tw-pt-4 tw-px-4">
				<p
					className={`tw-pb-2 ${active_tab === 0 ? "tw-border-b-2 tw-text-secondary-lighter tw-font-bold tw-border-secondary-lighter" : ""}`}
					onClick={() => setActiveTab(0)}
				>
					Route Plan
				</p>
				<p
					className={`tw-pb-2 ${active_tab === 1 ? "tw-border-b-2 tw-text-secondary-lighter tw-font-bold tw-border-secondary-lighter" : ""}`}
					onClick={() => setActiveTab(1)}
				>
					Track Location
				</p>
			</div>
			<div className="tw-flex tw-border-t tw-px-4">
				<div className={`tw-w-full md:tw-w-[80%] ${active_tab === 1 ? "max-md:tw-hidden" : ""}`}>
					<div className="tw-pr-4 tw-py-2 tw-text-xs tw-text-primary tw-font-bold tw-tracking-wide tw-mt-6">
						PLANNED VISITS ({Object.keys(planned_visits || {}).length})
					</div>
					<div className="tw-divide-y">
						{Object.keys(planned_visits || {}).map((user_id) => (
							<div className="tw-px-4 tw-py-2 hflex tw-gap-4" key={user_id}>
								<span className="tw-ml-2">{planned_visits[user_id].name}</span>
								{!staff_activity?.customer_visits?.[user_id] ? (
									user._id === staff._id ? (
										<button
											className="tw-bg-yellow-500 tw-text-xs tw-rounded tw-px-2 tw-py-1"
											onClick={() => checkInCustomer(planned_visits[user_id])}
										>
											Mark Visited
										</button>
									) : (
										<FontAwesomeIcon icon={faCircleExclamation} className="tw-text-amber-600" />
									)
								) : (
									<FontAwesomeIcon icon={faCheckCircle} className="tw-text-green-600" />
								)}
							</div>
						))}
					</div>
					<div className="tw-pr-4 tw-py-2 tw-text-xs tw-text-primary tw-font-bold tw-border-t tw-border-dashed tw-tracking-wide tw-mt-6">
						ADDITIONAL VISITS ({additional_visits.length})
					</div>
					<div className="tw-divide-y">
						{additional_visits.map((user_id) => (
							<div className="tw-px-4 tw-py-2 hflex tw-gap-4" key={user_id}>
								<span className="tw-ml-2">{staff_activity?.customer_visits[user_id]?.user?.name}</span>
								<FontAwesomeIcon icon={faCheckCircle} className="tw-text-green-600" />
							</div>
						))}
					</div>
				</div>
				<div
					id="staff_map"
					className={`tw-h-[420px] tw-w-full tw-my-2 ${active_tab === 0 ? "max-md:tw-hidden" : ""}`}
				></div>
			</div>
			<ResponsivePopup
				is_full_screen={true}
				show={edit_route_plan}
				onClose={() => setEditRoutePlan(null)}
				title={`${edit_route_plan?.length ? "Edit" : "Create"} Route Plan`}
			>
				<div className="tw-pb-12">
					<div className="hflex tw-pr-4 tw-py-2 tw-bg-gray-bg">
						<UserBadge user={staff} />
						<span>
							<DateView millis={date.getTime()} exclude_time={true} />
						</span>
					</div>
					<div className="tw-border-t tw-px-4 tw-py-2">
						<CustomersList is_select_list={true} selected_users={edit_route_plan} setSelectedUsers={setEditRoutePlan} />
					</div>
					<div className="tw-absolute tw-bottom-0 tw-w-full tw-px-4 tw-py-2 tw-bg-white tw-border-t hflex tw-gap-4">
						<button className="btn-basic tw-text-sm" onClick={() => setEditRoutePlan(null)}>
							Cancel
						</button>
						<div>
							<span className="tw-text-xs tw-text-gray-500">{edit_route_plan?.length} selected&nbsp;&nbsp;</span>
							<button className="btn-primary tw-text-sm" onClick={saveRoutePlan}>
								Save
							</button>
						</div>
					</div>
				</div>
			</ResponsivePopup>
		</>
	);
}

function StaffUser({ delivery_route_short, user }) {
	const [staff_wallet, setStaffWallet] = useState(user.staff_wallet);
	const current_user = useCurrentUser();

	const depositMoneyToFinanceManager = () => {
		let to_deposit = parseInt(window.prompt("Deposit into Finance Manager's wallet for the offline cash collected."));
		if (isNaN(to_deposit)) return;
		axios
			.post(`/api/admin/delivery_route/${delivery_route_short._id}/user/${user._id}/collect_cash`, {
				amount: to_deposit,
			})
			.then((resp) => {
				if (resp.data.errors) {
					Popup.show("Errors", <GenericException ex={resp.data.errors} />);
					return;
				}
				console.log(resp.data);
				Popup.toast(
					<div className="tw-rounded tw-p-2 tw-text-sm">
						Deposited {to_deposit} {delivery_route_short.currency} into the Finance Manager wallet.
					</div>,
					2500,
				);

				setStaffWallet(resp.data.paying_staff_wallet);
			});
	};

	const viewLedger = () => {
		Popup.show("Ledger Entries", <WalletLedgerEntries wallet={staff_wallet} />);
	};

	const openStaffNotes = () => {
		ChatSession.open(`staff_notes_${delivery_route_short._id}_${user._id}`);
	};

	const trackLocation = () => {
		Popup.show("Track Location", <TrackStaffLocation delivery_route_id={delivery_route_short._id} staff={user} />);
	};

	const showOptions = (evt) => {
		Popup.showContextMenu(
			evt.target,
			<div className="w3-list w3-list-bordered">
				{current_user?.roles?.finance_manager && staff_wallet?.wallet_amount < 0 ? (
					<div className="w3-padding-8" onClick={depositMoneyToFinanceManager}>
						Collect Cash
					</div>
				) : null}
				{staff_wallet?.user_id ? (
					<div className="w3-padding-8" onClick={viewLedger}>
						View Ledger
					</div>
				) : null}
				<div className="w3-padding-8" onClick={trackLocation}>
					Track Location
				</div>
				<div className="w3-padding-8" onClick={openStaffNotes}>
					Staff Notes{" "}
				</div>
			</div>,
		);
	};

	return (
		<div className="tw-flex tw-py-2" key={user._id} onClick={showOptions}>
			<div className="tw-w-10 tw-h-10 tw-rounded-full flex-box tw-text-secondary tw-bg-secondary-bg tw-overflow-hidden">
				{!user.image ? (
					<span>{user.name?.slice(0, 1)}</span>
				) : (
					<img alt={user.name} src={user.image} className="tw-object-fill" />
				)}
			</div>
			<div className="tw-grow tw-px-2">
				<div className="tw-flex tw-items-center">
					<div className="tw-text-gray-600 tw-text-sm tw-font-bold">{user.name}</div>
					{user.roles?.finance_manager ? (
						<div className="tw-ml-4 tw-text-xs tw-px-2 tw-py-[2px] tw-text-white tw-rounded tw-bg-green-600">
							Finance Manager
						</div>
					) : null}
				</div>
				<div className="tw-text-gray-500 tw-text-sm">{user.phone_number || user.email_id}</div>
			</div>
			{staff_wallet ? (
				<div
					className={`tw-flex-none tw-text-sm tw-self-end tw-mx-10 ${staff_wallet.wallet_amount < 0 ? "tw-text-red-600" : "tw-text-gray-600"}`}
				>
					{round(staff_wallet.wallet_amount)} {staff_wallet.wallet_currency}
				</div>
			) : null}
		</div>
	);
}

export default StaffUser;
