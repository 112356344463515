import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FileUploader } from "base/file_uploader";
import { GenericException } from "base/ui/errors";
import { Popup } from "base/ui/popups";
import { getStartOfDay } from "base/utils/common";
import { setKeyPath } from "base/utils";
import { getBodyVariables, getTemplateBody } from "./BroadcastHelper";
import ToggleSwitch from "../components/ui/ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const NOTIFICATION_TYPES = {
  PRODUCTS: "products",
  ROUTE: "",
  TEMPLATE: "template",
};

const BUTTON_TYPES = {
  QUICK_REPLY: "QUICK_REPLY",
  URL: "URL",
};

const template_input_options = [
  { title: "Username", id: "{{name}}" },
  { title: "Free Text", id: "custom" },
  { title: "Upload CSV/Excel sheet with specific column", id: "csv" },
  { title: "Custom Variable", id: "custom_variable" },
  // { title: "Area", id: "area" },
];

function TemplateVariables({ text, ctx, onSave }) {
  const [variables, setVariables] = useState(getBodyVariables(text));
  setKeyPath(ctx, "template_params.wa_body_text_params", variables);
  return (
    <div className="tw-p-2">
      <div className="tw-text-xs tw-text-gray-500">{text}</div>
      <div className="tw-mt-4">
        {variables?.map((variable, i) => (
          <EachVariable
            key={i}
            variable={variable}
            onChange={(val) => {
              ctx.template_params.wa_body_text_params[i] = val;
            }}
          />
        ))}
        {/* <div className='tw-text-xs tw-pt-4 tw-leading-[20px]'><span className='tw-text-secondary'>ⓘ Please Note:</span> For variables, you need to upload a CSV / Excel file with the variable values in next step.</div> */}
      </div>
      <div className="tw-flex tw-px-4 tw-pt-2 tw-border-t tw-relative tw-justify-end">
        <button
          onClick={onSave}
          className="tw-px-4 tw-py-2 tw-bg-yellow-500 tw-rounded tw-text-sm"
        >
          Save
        </button>
      </div>
    </div>
  );
}

function EachVariable({ variable, onChange }) {
  const [selected_input_type, setSelectedInputType] = useState("{{name}}");

  useEffect(() => {
    onChange(selected_input_type);
  }, [selected_input_type]);

  return (
    <div className="tw-flex tw-gap-1 tw-my-2 tw-text-xs tw-items-end tw-gap-2">
      <p className="tw-p-2 tw-bg-[#D9D9D9] tw-rounded tw-text-center tw-h-max">
        {variable}
      </p>
      <div>
        <p className="tw-text-xxs tw-text-gray-400 tw-mb-1">Variable Type</p>
        <select
          className="tw-text-sm tw-border tw-border-gray-300 tw-rounded tw-px-2 tw-py-1 "
          defaultValue={selected_input_type}
          onChange={(e) => {
            // if (e.target.value === "csv" && select_users_type !== "csv"){            }
            setSelectedInputType(e.target.value);
          }}
        >
          {template_input_options.map((input_option, i) => {
            return (
              <option key={i} value={input_option.id}>
                {input_option.title}
              </option>
            );
          })}
        </select>
      </div>
      {(selected_input_type === "custom" ||
        selected_input_type === "custom_variable") && (
        <div>
          <p className="tw-text-xxs tw-text-gray-400 tw-mb-1">Input</p>
          <input
            className={
              "tw-text-xs tw-p-1.5 tw-border tw-border-gray-300 tw-rounded"
            }
            onChange={(evt) => {
              onChange(
                selected_input_type === "custom_variable"
                  ? `{{${evt.target.value}}}`
                  : evt.target.value
              );
            }}
          />
        </div>
      )}
      {selected_input_type === "csv" && (
        <div>
          <p className="tw-text-xxs tw-text-gray-400 tw-mb-1">
            CSV Column Name
          </p>
          <p
            className={
              "tw-text-xs tw-p-1.5 tw-border tw-border-gray-300 tw-rounded"
            }
          >
            {`body_param_${variable.replace("{{", "").replace("}}", "")}`}
          </p>
        </div>
      )}
    </div>
  );
}

const getProductsButtonPayload = (selected_products_map, delivery_route_id) => {
  const products = Object.entries(selected_products_map)
    .map(([key, value]) => `${key}_${value}`)
    .join(":");
  return `${delivery_route_id}:${products}`;
};

function ButtonPayload({ button, delivery_route, onSave }) {
  const [type, setType] = useState(button.payload_type || "");

  const onProductsSelect = ({ products_qty }) => {
    button.payload = getProductsButtonPayload(
      products_qty,
      delivery_route?._id
    );
  };

  const onTypeChange = (evt) => {
    setType(evt.target.value);
    button.payload_type = evt.target.value;
  };

  return (
    <div className="tw-mb-2 tw-p-4 tw-border tw-rounded tw-relative tw-text-xs">
      <div className="tw-my-2">
        Button Type:{" "}
        {button.type === BUTTON_TYPES.URL ? "URL + Suffix" : "Quick Reply"}
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-items-end">
        {button.type == BUTTON_TYPES.QUICK_REPLY ? (
          <>
            <div>
              <div className="tw-text-xxs tw-text-gray-500 tw-mb-1">
                Button Type
              </div>
              <select
                className="tw-outline-0 tw-border tw-border-gray-300 tw-p-2 tw-radius tw-text-sm tw-rounded tw-max-w-full"
                defaultValue={button.payload_type}
                onChange={onTypeChange}
              >
                <option value={""}>Perform Action (Default)</option>
                <option value={"echo:"}>Display Message</option>
                {delivery_route ? (
                  <option value={"add_products:"} disabled={!delivery_route}>
                    Products{" "}
                    {!delivery_route
                      ? "( ⓘ Please choose Delivery Route to enable this )"
                      : null}
                  </option>
                ) : null}
              </select>
            </div>
            <div className="tw-grow">
              <div className="tw-text-xxs tw-text-gray-500 tw-mb-1">
                Button Payload{" "}
                <span className="tw-text-red-600 tw-text-sm tw-leading-4">
                  *
                </span>
              </div>
              {/* {type === "add_products:" ? (
                <SelectProducts
                  delivery_route={delivery_route}
                  onSelect={onProductsSelect}
                  className={"tw-my-6"}
                />
              ) : ( */}
              <input
                className="tw-text-sm tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded tw-w-full"
                placeholder="Enter payload"
                defaultValue={button.payload}
                onChange={(evt) => (button.payload = evt.target.value)}
              />
              {/* )} */}
            </div>
          </>
        ) : button.type === BUTTON_TYPES.URL && button.url?.includes("{{") ? (
          <div className="tw-grow">
            <div className="tw-text-xxs tw-text-gray-500 tw-mb-1">
              URL{" "}
              <span className="tw-text-red-600 tw-text-sm tw-leading-4">*</span>
              <span className="tw-text-xs tw-mx-2">
                ({decodeURIComponent(button.url)})
              </span>
            </div>
            <input
              className="tw-text-sm tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded tw-w-full"
              placeholder="Enter URL suffix - {{1}}"
              defaultValue={button.payload}
              onChange={(evt) => (button.payload = evt.target.value)}
            />
          </div>
        ) : null}
      </div>
      <div className="tw-flex tw-px-4 tw-pt-2 tw-border-t tw-relative tw-justify-end">
        <button
          onClick={onSave}
          className="tw-px-4 tw-py-2 tw-bg-yellow-500 tw-rounded tw-text-sm"
        >
          Save
        </button>
      </div>
    </div>
  );
}

function CreateBroadcast({
  org,
  delivery_route,
  template,
  selected_users_ui,
  url_params,
  onSubmit,
}) {
  const parsed_template = getTemplateBody(template);
  const [selected_template_object, setSelectedTemplateObject] =
    useState(parsed_template);
  const [text, setText] = useState(parsed_template.body);
  const body_variables = getBodyVariables(parsed_template.body);
  const [wa_header_text, setWaHeaderText] = useState(
    parsed_template.header_text
  );
  const header_variables = getBodyVariables(parsed_template.header_text);
  const [files, setFiles] = useState();
  const [is_publishing, setIsPublishing] = useState(false);
  const [selected_route, setSelectedRoute] = useState(delivery_route || null);
  const ctx = useRef({
    messageType: NOTIFICATION_TYPES.TEMPLATE,
    template_params: { wa_template_name: template.name },
  }).current;
  const [is_scheduled, setIsScheduled] = useState(false);
  const [tags_operator, setTagsOperator] = useState("OR");
  const [select_users_type, setSelectUsersType] = useState("");
  const buttons = () => {
    selected_template_object.buttons?.forEach((btn, i) => {
      btn.payload_full = btn.payload
        ? `${btn.payload_type || ""}${btn.payload}`
        : null;
      btn.index = i;
    });

    /* If template buttons */
    if (ctx.messageType === NOTIFICATION_TYPES.TEMPLATE) {
      const wa_button_quickreply_payloads = [],
        wa_url_button_suffixes = [];
      selected_template_object.buttons?.forEach((btn) => {
        if (btn.payload_full) {
          /* transform them to [[index, payload]] */
          if (btn.type === BUTTON_TYPES.URL) {
            wa_url_button_suffixes.push([`${btn.index}`, btn.payload_full]);
          } else {
            wa_button_quickreply_payloads.push([
              `${btn.index}`,
              btn.payload_full,
            ]);
          }
        }
      });
      return { wa_button_quickreply_payloads, wa_url_button_suffixes };
    }
    /* If route notification buttons */
    return ctx.button_payloads?.length
      ? ctx.button_payloads.map((btn) => [
          btn.payload_full,
          btn.text,
        ]) /* transform them to [[payload, text]] */
      : null;
  };

  const configureVariables = () => {
    const popup = Popup.show(
      "Configure Variables",
      <div className="tw-mb-2 tw-p-2">
        <TemplateVariables text={text} ctx={ctx} onSave={() => popup.close()} />
      </div>
    );
  };

  const showSampleUsersSheet = () => {
    Popup.show(
      "Sample Users Sheet",
      <img
        src={
          "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-08-21/1692615697U8PLYWFNTG__Screenshot_20230821_at_4.29.png"
        }
        alt="Sample Users Sheet"
      />
    );
  };

  const addButtonPopUp = (button) => {
    const popup = Popup.show(
      "Configure Button",
      <div className="tw-mb-2">
        <ButtonPayload
          button={button}
          delivery_route={delivery_route}
          onSave={() => {
            popup.close();
          }}
        />
      </div>
    );
  };
  const doSendNotification = (is_test) => {
    if (!validateNotification()) return;
    if (!window.confirm(`Are you sure to send this notification?`)) {
      return;
    }
    if (is_publishing || !text || !text?.trim()) return;

    const button_payloads = buttons();

    const payload = {
      text: text,
      files: files,
      wa_header_text: wa_header_text || null,
      tags: ctx.selected_tags,
      users_csv_file: select_users_type === "csv" ? ctx.users_csv_file : null,
      delivery_org_id: org._id,
      buttons:
        ctx.messageType !== NOTIFICATION_TYPES.TEMPLATE
          ? button_payloads
          : null,
      delivery_route_id: selected_route?._id || null,
      tags_operator: tags_operator,
    };
    if (is_test) {
      payload["is_test"] = true;
    }
    // if (delivery_route) {
    // 	payload.delivery_point_name = "--NA--";
    // 	payload.delivery_date = "--NA--";
    // }

    if (ctx.scheduled_at || ctx.scheduled_at_time) {
      payload["scheduled_at"] =
        getStartOfDay(new Date(ctx.scheduled_at)).getTime() +
        (ctx.scheduled_at_time || 0);
    }

    if (ctx.messageType === NOTIFICATION_TYPES.TEMPLATE) {
      if (files?.length > 0) {
        ctx.template_params["wa_header_file_url"] = files[0];
      }
      ctx.template_params.wa_button_quickreply_payloads =
        button_payloads.wa_button_quickreply_payloads;
      ctx.template_params.wa_url_button_suffixes =
        button_payloads.wa_url_button_suffixes;
      payload["wa_template_params"] = ctx.template_params;
    }

    setIsPublishing(true);
    axios
      .post(`/api/admin/org/${org._id}/broadcasts?action=create`, {
        ...payload,
        ...(url_params || {}),
      })
      .then((resp) => {
        if (resp.data.errors) {
          Popup.show("Error", <GenericException ex={resp.data.errors} />);
          return;
        }
        if (is_test) {
          Popup.toast(
            "Test broadcast sent successfully to your WhatsApp",
            3000
          );
          return;
        }
        onSubmit && onSubmit();
        Popup.show(
          "",
          <div className="tw-p-4 tw-text-sm tw-grid tw-justify-items-center tw-font-bold">
            <FontAwesomeIcon icon={faCheckCircle} color="green" size="2xl" />
            <p className="tw-mt-4">Broadcast created successfully</p>
          </div>
        );
      })
      .finally(() => setIsPublishing(false));
  };

  const validateNotification = () => {
    if (
      !ctx.users_csv_file &&
      ctx.template_params?.wa_body_text_params?.some(
        (variable_val) => !variable_val?.trim()
      )
    ) {
      window.alert(
        "Please fill the template variables in previous step or upload a CSV."
      );
      return false;
    }
    if (
      selected_template_object.image &&
      !isHeaderText() &&
      (files == null || files.length == 0)
    ) {
      window.alert("Please select the header image to proceed.");
      return false;
    }
    if (
      selected_template_object.buttons?.some(
        (btn) =>
          (btn.type === BUTTON_TYPES.QUICK_REPLY ||
            (btn.type === BUTTON_TYPES.URL && btn.url?.includes("{{"))) &&
          !btn.payload?.trim()
      )
    ) {
      window.alert("Please configure the buttons to proceed.");
      return false;
    }
    if (select_users_type === "csv" && !ctx.users_csv_file) {
      window.alert("Please upload a CSV to proceed");
      return false;
    }
    // if (
    // 	!selected_route &&
    // 	!ctx.users_csv_file &&
    // 	!ctx.selected_tags?.length &&
    // 	(!query_details || !query_details.broadcast_id)
    // ) {
    // 	window.alert("Please select users to continue");
    // 	return false;
    // }
    return true;
  };

  const isHeaderText = () => {
    return selected_template_object?.header_format?.toLowerCase() === "text";
  };

  const onProductsSelect = ({ products_qty }) => {
    ctx.button_payloads = [
      {
        payload: getProductsButtonPayload(products_qty, selected_route._id),
        text: "Shop Now",
        payload_type: "add_products:",
        type: BUTTON_TYPES.QUICK_REPLY,
      },
    ];
  };

  function BroadcastPreview() {
    return (
      <div className="tw-flex tw-px-4 md:tw-px-4 tw-pt-4 tw-bg-[#efecea] md:tw-w-[65%] tw-p-12 tw-justify-center">
        <div className="tw-pb-2 tw-pt-8">
          <div className="tw-text-sm tw-mx-1 tw-items-start">
            {selected_template_object.image ? (
              <div className="tw-bg-white tw-max-w-xs tw-border-x tw-border-t tw-rounded-t-xl tw-p-2 tw-flex tw-justify-center">
                {isHeaderText() ? (
                  <div className="tw-bg-white tw-max-w-xs tw-border-x tw-border-b tw-rounded-b-xl tw-p-2">
                    {wa_header_text}
                    {/* {
                    header_variables
                      ? <div className='tw-mt-4'>
                        <button className='tw-bg-white tw-border tw-border-landing-green tw-text-landing-green tw-text-xs tw-rounded tw-px-2 tw-py-1'>Configure Variables</button>
                      </div>
                      : null
                  } */}
                  </div>
                ) : (
                  <FileUploader
                    files={files}
                    allowed_mime_types={[
                      "image/jpeg",
                      "image/png",
                      "image/jpg", //images
                      "video/mp4", // videos
                      "application/pdf",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // documents
                    ]}
                    onFilesUpdated={(files) =>
                      setFiles(files?.map((file) => file.url))
                    }
                  />
                )}
              </div>
            ) : null}

            <div className="tw-flex tw-gap-2">
              <div className="tw-bg-white tw-max-w-xs tw-border-x tw-border-b tw-rounded-b-xl tw-p-2 tw-w-full">
                {text}
                <div className="tw-text-gray-400 tw-mt-2 tw-text-xxs">
                  {ctx.footer_text || ""}
                </div>
              </div>
              {body_variables ? (
                <div className="tw-mt-4 ">
                  <button
                    className="tw-bg-white tw-border tw-border-landing-green tw-text-landing-green tw-text-xs tw-rounded tw-px-2 tw-py-1"
                    onClick={() => {
                      configureVariables(ctx);
                    }}
                  >
                    Configure Variables
                  </button>
                </div>
              ) : null}
            </div>
            {selected_template_object.buttons?.length ? (
              <div className="tw-mt-2">
                {selected_template_object.buttons?.map((button, i) => {
                  return (
                    <div key={i} className="tw-w-full tw-flex tw-gap-2 tw-mt-2">
                      <button className="tw-max-w-xs tw-border-x tw-p-2 tw-text-xs tw-rounded-lg tw-w-full tw-inline-block tw-text-center tw-bg-white tw-text-sky-700 tw-drop-shadow">
                        {button.text}
                      </button>
                      {button.type === BUTTON_TYPES.QUICK_REPLY ||
                      button.url?.includes("{{") ? (
                        <div className="">
                          <button
                            className="tw-bg-white tw-border tw-border-landing-green tw-text-landing-green tw-text-xs tw-rounded tw-px-2 tw-py-1"
                            onClick={() => {
                              addButtonPopUp(button);
                            }}
                          >
                            Configure Button
                          </button>
                          <p className="tw-text-gray-400 tw-text-xxs tw-mt-0.5">
                            {button.type}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className=" tw-bg-white">
      <div className="tw-px-4 tw-py-2 tw-text-black tw-font-medium tw-flex tw-justify-end">
        <div className="tw-flex tw-gap-2 tw-items-center">
          <button
            className="tw-border tw-border-primary tw-text-primary tw-rounded-md tw-px-4 tw-py-2 tw-text-sm"
            onClick={() => {
              doSendNotification(true);
            }}
          >
            Test Broadcast
          </button>
          <button
            className="tw-bg-primary tw-text-white tw-rounded-md tw-px-4 tw-py-2 tw-text-sm"
            onClick={() => doSendNotification(false)}
          >
            Send to Users
          </button>
        </div>
      </div>
      <div className={`tw-grid tw-grid-cols-1 md:tw-flex tw-my-4`}>
        <BroadcastPreview />
        <div className="md:tw-w-[40%] tw-bg-white tw-border tw-px-4 md:tw-px-6 tw-pt-4">
          <p>Configurations</p>
          {/* scheduled_at date time picker -> millis */}
          <div className="tw-mt-4">
            <div className="tw-flex tw-items-center tw-gap-4 tw-text-sm tw-mb-2 tw-text-gray-900">
              Schedule Broadcast
              <ToggleSwitch
                defaultChecked={is_scheduled}
                onChange={(evt) => {
                  setIsScheduled(evt.target.checked);
                }}
              />
            </div>
            {is_scheduled ? (
              <div className="tw-items-center tw-gap-2 tw-mt-4">
                <input
                  type="date"
                  className="tw-border tw-border-gray-300 tw-rounded tw-px-4 tw-py-2 tw-w-full tw-text-sm"
                  onInput={(evt) => {
                    ctx.scheduled_at = new Date(evt.target.value).getTime();
                  }}
                />
                <input
                  type="time"
                  className="tw-border tw-border-gray-300 tw-rounded tw-px-4 tw-py-2 tw-mt-2 tw-w-full tw-text-sm"
                  onInput={(evt) => {
                    let parts = evt.target.value.split(":");
                    ctx.scheduled_at_time =
                      parseInt(parts[0]) * 60 * 60 * 1000 +
                      parseInt(parts[1]) * 60 * 1000;
                  }}
                />
              </div>
            ) : null}
            <div
              onChange={(evt) => {
                evt.target.name === "select_users_type" &&
                  setSelectUsersType(evt.target.value);
              }}
            >
              {selected_users_ui ? (
                <label className="tw-flex tw-flex-row tw-gap-2 tw-items-start tw-my-4">
                  <input
                    type="radio"
                    className="w3-radio"
                    name="select_users_type"
                    value=""
                    defaultChecked={true}
                  />
                  <div className="tw-grow">{selected_users_ui}</div>
                </label>
              ) : null}
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-start tw-my-4">
                <input
                  type="radio"
                  className="w3-radio"
                  name="select_users_type"
                  value="csv"
                />
                <div className="tw-grow">
                  CSV / Excel Upload
                  <div
                    className={`tw-pt-2 ${
                      select_users_type === "csv" ? "" : "tw-hidden"
                    }`}
                  >
                    <FileUploader
                      allowed_mime_types={[
                        "text/csv",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ]}
                      onFilesUpdated={(files) =>
                        (ctx.users_csv_file = files.length
                          ? files[0].url
                          : null)
                      }
                    />
                    <div
                      onClick={showSampleUsersSheet}
                      className="tw-text-primary tw-underline tw-cursor-pointer tw-text-xxs tw-float-right"
                    >
                      View Sample File
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateBroadcast;
