import { isObject, isString } from "../utils";
import WarningIcon from "../images/icons/warning.png" 
import { broadcaster } from "../utils/events";
import { Popup } from "./popups";
import { obj2HTML } from "../utils/common";
import React from 'react';
import { WarningView } from "./status";
function GenericException({ex}){

    let errors = [];

    if(ex.isAxiosError){
        ex = ex.response.data?.errors?.exception || ex.response.data;
    }

    if(isString(ex) && (ex[0] === "[" || ex[0] === "{")){
        try{
            ex = JSON.parse(ex);
        } catch(e){}
    }

    if(isObject(ex)){
        for(let [k, v] of Object.entries(ex)){
            errors.push(
                (!v || (v.toString().length < 8) ?  (k + ": ") : "") + obj2HTML(v)
            );
        }
    }
    else if(Array.isArray(ex)){
        errors.push(...ex);
    } 
    else {
        errors.push(ex + "");
    }
    
    return errors.length === 0 ? null : (
        <div className="w3-round w3-pale-red w3-margin-bottom-8 w3-padding-4 w3-flex w3-flex-vcenter">
            <div className='w3-padding-8-4'>
                <img src={WarningIcon} width="32px"/>
            </div>
            <div className='w3-padding-sides-4 w3-padding-top-8'>
                { errors.map( (v, i) => <div key={i} className="w3-margin-bottom-8" dangerouslySetInnerHTML={{__html:v}}/>) }
            </div>
        </div>
    );
}

GenericException.showPopup = function(ex, title){
    let popup;
    if(ex.need_user){
        if(broadcaster.broadcast_event("error:need_user")){
            return; // handled
        }
    }
    popup = Popup.show(
        title || "Sorry! Error occured",
        <GenericException ex={ex} />
    )
    return popup;
}

export {GenericException}
