import { faSpinner, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import NotFoundIcon from '../images/icons/not-found.svg'

function LoadingView({title, height}){
    return (
        <div className="w3-expand w3-relative" style={height && {"height": height}}>
            <div className="w3-display-middle w3-center">
                <div><FontAwesomeIcon icon={faSpinner} className="w3-animate-spin"/></div>
                {typeof title === "string" ? <h3>{title || "Loading.."}</h3> : title}
            </div>
        </div>
    )
}

function EmptyView({title, height}){
    return (
        <div className="w3-expand w3-relative" style={height && {"height": height}}>
            <div className="w3-display-middle w3-center">
                {typeof title === "string" ? <h3>{title}</h3> : title}
            </div>
        </div>
    );
}

function WarningView({title, height}) {
    return (
        <div className="w3-padding-topbottom-32 w3-center">
            <FontAwesomeIcon icon={faWarning} style={{height:50}} className="w3-text-amber" />
            <div className="w3-padding-topbottom-32 w3-padding-sides-4">{title || "Please login to view this page"}</div>
        </div>
    )
}

function NotFoundView({title, subtitle, image}) {
    return (
        <div className="w3-padding-topbottom-32 w3-center">
            <img src={image || NotFoundIcon} style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '50%'}} />
            <div className="w3-padding-topbottom-32 w3-padding-sides-4">{title || "Sorry! Something went wrong. Please try again after sometime."}</div>
            {subtitle ? <div className="w3-padding-bottom">{subtitle}</div> : null}
        </div>
    )
}

function LoadingOverlay({title, height}) {
    /* TODO: use w3-css here so that it can be used for other apps which doesn't have tailwind */
    return (
        <div className='tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75 tw-text-white flexbox tw-z-40'
            onClick={(e) => e.stopPropagation()}
        >
            <LoadingView title={title} height={height}/>
        </div>
    )
}


export {LoadingView, EmptyView, LoadingOverlay, WarningView, NotFoundView};
