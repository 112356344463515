import React, { useState, useEffect } from "react";
import { IS_MOBILE, WEB_PUSH_PUBLIC_KEY } from "../constants";
import { broadcaster, setBroadcastHookState } from "./events";
import { getCurrentUser } from "../app";
import axios from 'axios';
import { isString, round } from "../utils";

function debounce(ctx, delay, func, key){
	key = key || "debounce";
	if(ctx[key]){
		clearTimeout(ctx[key]);
	}
	ctx[key] = setTimeout(func, delay);
}

var ExtToMimeType = {
	"jpg": "image/jpeg",
	"jpeg": "image/jpeg",
	"png": "image/png",
	"gif": "image/gif",
	"mp3": "audio/mp3",
	"wav": "image/wav",
	"ogg": "audio/ogg",
	"mp4": "video/mp4",
	"webp": "image/webp",
	"svg": "image/svg+xml",
	"pdf": "application/pdf"
}

function b64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
	  .replace(/\-/g, '+')
	  .replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
	  outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}


function isImage(file){
	if(file.mime_type){
		if(!file.mime_type.startsWith("image")){
			return false;
		}
		return file.mime_type;
	}
	let file_url = file.thumbnail || file.url || file;
	if(file_url && file_url.endsWith){
		let match = file_url.match(/\.(jpg|jpeg|png|gif|webp|svg)($|\?)/i);
		return match && ExtToMimeType[match[1]];
	}
	return false;
}

function isAudio(file){
	if(file.mime_type){
		if(file.mime_type){
			if(!file.mime_type.startsWith("audio")){
				return false;
			}
			return file.mime_type;
		}
	
		file = file.url;
	}
	let file_url = file.url || file;
	if(file_url && file_url.endsWith){
		let match = file_url.match(/\.(mp3|wav|ogg)($|\?)/i);
		return match && ExtToMimeType[match[1]];
	}
	return false;
}

function isVideo(file){
	if(file.mime_type){
		if(!file.mime_type.startsWith("video")){
			return false;
		}
		return file.mime_type;
	}
	let file_url = file.url || file;
	if(file_url && file_url.endsWith){
		let match = file_url.match(/\.(mp4)($|\?)/i);
		return match && ExtToMimeType[match[1]];
	}
	return false;
}

function isPdf(file) {
	if(file.mime_type){
		if(!file.mime_type.startsWith("pdf")){
			return false;
		}
		return file.mime_type;
	}
	let file_url = file.url || file;
	if(file_url && file_url.endsWith){
		let match = file_url.match(/\.(pdf)($|\?)/i);
		return match && ExtToMimeType[match[1]];
	}
	return false;
}

function getStartOfDay(date){
	date = date || new Date();
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

var _counter = 0;
function useRerender(){
	const [value, setValue] = useState(0);
	return () => setValue(++_counter);   
}

function useRerenderWithValue(){
	const [value, setValue] = useState(0);
	return [value, () => setValue(++_counter)];   
}


/* webpush */
navigator?.serviceWorker?.ready.then((service_worker_registration) => {
	// Do we already have a push message subscription?
	if(service_worker_registration.pushManager){
		setBroadcastHookState("web_push_support", true);   
		service_worker_registration.pushManager
			.getSubscription()
			.then((subscription) => {
				setBroadcastHookState("web_push_subscription", subscription);       
			});
	}
});

function requestWebPush(cb){
	navigator.serviceWorker.ready.then((service_worker_registration) => {
		service_worker_registration.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: b64ToUint8Array(WEB_PUSH_PUBLIC_KEY)
		}).then(
			(subscription) => {
				setBroadcastHookState("web_push_subscription", subscription);       
			},
			(error) => {
				setBroadcastHookState("web_push_subscription", null);       
			}
		);
	});
}

/* common base endpoint for push */

broadcaster.add_event_listener("web_push_subscription", async (subscription) => {
	if(!subscription) return;
	let user = await getCurrentUser();
	let web_push_token = JSON.stringify(subscription);
	if(localStorage.getItem("WEB_PUSH_TOKEN_KEY") != `${user?._id}_${web_push_token}`){
		axios.post(
			"/update_push_token", 
			{
				"push_token": web_push_token,
				"os_type": "WEB_PUSH"
			}
		).then((resp) => {
			localStorage.setItem("WEB_PUSH_TOKEN_KEY", `${user?._id}_${web_push_token}`);
		});
	}
});

/* webpush end */

/* standalone app install */
var deferedPwaPrompt = null;
window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
	deferedPwaPrompt = e;
	setBroadcastHookState('app_install_support', true);
});

async function requestAppInstall(){
	if(deferedPwaPrompt){
		//ask to install as  app
		deferedPwaPrompt.prompt();
		return await deferedPwaPrompt.userChoice; // {accepted, dismissed, not_supported}
	}
	return "not_supported";
}
const isInStandaloneMode = () =>
	  (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

/* standalone app install end */

function openWhatsapp(wa_number, text){
	let url = `https://wa.me/${wa_number}/?text=${encodeURIComponent(text)}`
	IS_MOBILE
	?  (window.location.href = url)
	:  window.open(url, "_blank", "noreferrer");
}

/* standalone app install end */

// generated by chatGPT
function obj2HTML(obj, indent = 0) {
	let html = "";
	if (Array.isArray(obj)) {
		for (let item of obj) {
		  html += obj2HTML(item, indent + 1);
		}
	} else if(typeof obj === "object"){
		for (let key in obj) {
			html += `<div style="margin-left: ${indent}em;"><b>${key}:</b></div>`;
			html += obj2HTML(obj[key], indent + 1);
		}
	} else {
		html += `<div style="margin-left: ${indent}em;"><b>${obj}</b></div>`
	}
	return html;
}

function objToEl(obj, indent = 0){
	if(obj === null || obj === undefined){
		return <div style={{marginLeft: indent + "em"}}>-</div>
	}
	else if (Array.isArray(obj)) {
		return <div style={{marginLeft: indent + "em"}}>
			{
				obj.map((item, i) => 
					<React.Fragment key={i} >
						{objToEl(item, indent)}
					</React.Fragment>
				)
			}
		</div>
	} else if(typeof obj === "object"){
		return <table className="w3-table-all w3-margin-topbottom-4" style={{marginLeft: indent + "em"}}>
			<tbody className="w3-bounded">
			{
				Object.entries(obj).map(([key, value], i) => 
					<tr key={i}>
						<td className="w3-text-grey">{key}</td>
						<td>{objToEl(value, indent + 1)}</td>
					</tr>
				)
			}
			</tbody>
		</table>
	} else {
		return <div style={{marginLeft: indent + "em"}}>{obj}</div>
	}
}

function listOfListsToTable(lists){
	return <table className="w3-table-all">
		<tbody>
		{
			lists?.map((list, i) => 
				<tr key={i}>
					{
						list.map((item, j) => <td key={j}>{item}</td>)
					}
				</tr>
			)
		}
		</tbody>
	</table>
}

const PendingPromise = {};
function loadGoogleMapsScript(api_key){
	if (window.google?.maps) {
		return Promise.resolve()
	}
	if(PendingPromise.gmaps){
		return PendingPromise.gmaps;
	}

	var resolver = null;
	let ret = PendingPromise.gmaps = new Promise((resolve, reject) => {resolver = resolve});
	const script = window.gmaps_script = document.createElement('script');
	window.initMap = () => {
		PendingPromise.gmaps = null;
		resolver();
	};
	script.src = `https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=places&callback=initMap&loading=async`;
	script.async = true;
	script.defer = true;
	document.body.appendChild(script);
	return ret;
}

function loadFbConnect(facebook_app_id){
	if(window.FB) return Promise.resolve();
	if(PendingPromise.fb_connect) return PendingPromise.fb_connect;

	var resolver = null;
	let ret = PendingPromise.fb_connect = new Promise((resolve, reject) => {resolver = resolve});
	window.fbAsyncInit = function () {
		// JavaScript SDK configuration and setup
		PendingPromise.fb_connect = null;
		window.FB.init({
			appId:    facebook_app_id, // Facebook App ID
			cookie:   true, // enable cookies
			xfbml:    true, // parse social plugins on this page
			version:  'v18.0' //Graph API version
		});
		resolver();
	};

	// Load the JavaScript SDK asynchronously
	(function (d, s, id) {
	var js, fjs = d.getElementsByTagName(s)[0];
	if (d.getElementById(id)) return;
	js = d.createElement(s); js.id = id;
	js.src = "https://connect.facebook.net/en_US/sdk.js";
	fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));

	return ret
}

const _scipts_loaded = {}
function loadScript(script_url){
	if (_scipts_loaded[script_url]) {
		return Promise.resolve()
	}
	if(PendingPromise[script_url]){
		return PendingPromise[script_url];
	}

	var resolver = null;
	let ret = PendingPromise[script_url] = new Promise((resolve, reject) => {resolver = resolve});
	const script = document.createElement('script');
	script.src = script_url;
	script.async = true;
	script.defer = true;
	script.onload = () => {
		_scipts_loaded[script_url] = true;
		PendingPromise[script_url] = null;
		resolver();
	}
	document.body.appendChild(script);
	return ret;
}


function nearestElement(el, selector) {
	while(el && !el.matches(selector)){
		el = el.parentElement;
	}
	return el
}


function getRandomColor(str, shade=0, colors_list){
	str = str || new Date().getTime().toString();
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	if(colors_list?.length) return colors_list[hash % colors_list.length];
	const s = (256 - shade) / 256;
	const r = parseInt(((hash & 0xFF0000) >> 16) * s + shade);
	const g = parseInt(((hash & 0x00FF00) >> 8) * s + shade);
	const b = parseInt((hash & 0x0000FF) * s  + shade);

	return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

const isValidEmail = (email_address) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email_address);
}

/**
 * This function checks if a given phone number is valid.
 *
 * @param {string} phone_number - The phone number to be validated.
 * @returns {boolean} - Returns true if the phone number is valid, false otherwise.
 *
 * The function uses a regular expression to check if the phone number matches the pattern of 6 to 18 digits.
 * The pattern allows for an optional '+' at the beginning, indicating international phone numbers.
 *
 * @example
 * isValidPhone('+254712345678'); // Returns true
 * isValidPhone('0712345678'); // Returns true
 * isValidPhone('12345'); // Returns false
 */
function isValidPhone(phone_number) {
	return /^[0-9\+]{6,18}$/.test(phone_number);
}

const sanitizeToId = (str) => str.replace(/[^0-9a-zA-Z]/g, "_");

const CURRENCY_SYMBOLS = {
	"KES": "KSh", "USD": "$", "INR":  "₹", "EUR": "€", "GBP": "£", "NGN": "₦", "RWF": "Rwf"
}

const CURRENCY_LOCALE = {
	"KES": "en-KE", "USD": "en-US", "INR": "en-IN", "EUR": "en-AL", "GBP": "en-GB", "NGN": "en-NG", "RWF": "en-RE"
}

const CURRENCY_DECIMALS = {'BHD':3, 'JOD':3, 'KWD':3, 'OMR':3, 'TND':3}

const currencyToSymbol = (currency) => CURRENCY_SYMBOLS[currency] || currency;
const currencyToLocale = (currency) => CURRENCY_LOCALE[currency] || "en-KE";

const defined = (...args) => args.find((arg) => arg !== undefined);

function getDisplayPrice (currency, price) {
	const formater_options = {
		style: "currency",
		currency: currency,
		minimumFractionDigits: CURRENCY_DECIMALS[currency] || 0,
	}
	return new Intl.NumberFormat(currencyToLocale(currency), formater_options).format(price / 1000)
}

function getPrice (currency, price) {
    if (CURRENCY_DECIMALS[currency] == 3) {
        return round(price / 1000, 3).toFixed(3)
    }
    return round(price / 1000)
}

function getPriceWithCurrency (currency, price) {
	return `${getPrice(currency, price)} ${currency}`
}

export {
	debounce, isImage, isAudio, isVideo, isPdf, getStartOfDay, openWhatsapp, useRerender, useRerenderWithValue,
	isInStandaloneMode, b64ToUint8Array, obj2HTML, requestAppInstall, requestWebPush,
	loadGoogleMapsScript, nearestElement, getRandomColor, loadFbConnect, sanitizeToId,
	loadScript, currencyToSymbol, CURRENCY_SYMBOLS, defined, isValidEmail, isValidPhone, currencyToLocale, objToEl,
	getDisplayPrice, getPrice, getPriceWithCurrency, listOfListsToTable
};
