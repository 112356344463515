import axios from "axios";
import { useEffect, useState } from "react";
import { broadcaster, useBroadcastedState } from "./utils/events";

var user = null;
var guest_user = null;
var pending_fetch_user_promise = null;

function getCurrentUser(){
    if(user){
        return Promise.resolve(user);
    }
    if(pending_fetch_user_promise){
        return pending_fetch_user_promise;
    }
    var resolver = null; 
    pending_fetch_user_promise = new Promise(
        (_resolver, reject) => { resolver = _resolver;}
    );
    
    axios.get("/user/me").then((resp) => {
        pending_fetch_user_promise = null;
        user = resp.data.user || null;  // explicitly set to null if there is no user, undefined vs null
        guest_user = resp.data.guest_user || null; // explicitly set to null if there is no user, undefined vs null  
        resolver(user || guest_user);
    });

    return pending_fetch_user_promise;
}

function useCurrentUser(){
    const [user, setUser] = useBroadcastedState("user_updated");
    useEffect(
        () => {
            if(user) return;
            getCurrentUser().then((user) => setUser(user));
        }, []
    )
    return user;
}

export {getCurrentUser, useCurrentUser};
