import { IS_DEV } from 'base/constants';
import mixpanel from 'mixpanel-browser';

// Can see mixpanel events in web console
const debug_mode = IS_DEV ? {debug: true} : {}

mixpanel.init('f02d17d50469c0bc576e0c153406bf50', {
  ...debug_mode,
  persistence: "localStorage",
});

const env_check = !IS_DEV; // always keep false only make it true for local testing

const EventTracker = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export default EventTracker;