import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Fuse from 'fuse.js'
import { LoadingView } from "base/ui/status";
import  WhatsappIcon from "../images/whatsapp.svg"
import { openWhatsapp } from "base/utils/common";
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategorySelectorStrip } from "base/ui/misc";

function DeliveryRoutesList() {
  const params = useParams();
  const delivery_org_id = params?.delivery_org_id || window.delivery_org_id || "sukhiba";
  const [delivery_org, setDeliveryOrg] = useState(null); // [delivery_org_id
  const [delivery_routes, setDeliveryRoutes] = useState([]);
  const [filtered_delivery_routes, setFilteredDeliveryRoutes] = useState([]);
  const [is_loading, setLoading] = useState(false);
  const [search_text, setSearchText] = useState("");
  const [all_categories, setAllCategories] = useState([]); // [category_id]
  const fetchDeliveryRoutesList = () => {
    if (is_loading) return;
    setLoading(true);    
    axios
    .get(`/api/dorg/${delivery_org_id}`)
    .then((resp) => {
      if (resp.data.errors) {
        return;
      }
      setDeliveryOrg(resp.data?.delivery_org );
      setDeliveryRoutes(resp.data?.delivery_routes);
      setFilteredDeliveryRoutes(resp.data?.delivery_routes);
      /* resp.data.delivery_routes[x].categories , concat, and remove duplicates */
      let categories = [];
      resp.data?.delivery_routes?.forEach((route) => {
        if(route.categories){
          categories = categories.concat(route.categories);
        }
      });
      setAllCategories([...new Set(categories)]);
      DeliveryRoutesList.search_index = new Fuse(
        resp.data.delivery_routes,
        {"keys": ["title", "description", "categories"], includeScore: true}
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(()=> {fetchDeliveryRoutesList()},[]);

  useEffect(
    () => {
      if(search_text){
        setFilteredDeliveryRoutes(
          DeliveryRoutesList.search_index.search(search_text).map((r) => r.item)
        );      
      } else {
        setFilteredDeliveryRoutes(delivery_routes)
      }
    },[search_text]
  )

  if(!delivery_org){
    return <LoadingView title={"Loading.."} height="300px" />
  }

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none">
      <div className="tw-relative tw-w-full tw-flex tw-px-2 tw-py-2 tw-bg-primary tw-text-white tw-items-center">
        <div className="tw-text-white tw-flex-1 tw-font-bold">
          <p>List of Available Delivery Shops</p>
          {
            filtered_delivery_routes &&
              <span className="tw-text-yellow-500 tw-text-xs">{filtered_delivery_routes.length + " delivery routes"}</span>
          }
        </div>

        <div className='tw-relative tw-border  tw-rounded-md tw-text-sm tw-px-1 tw-py-2 tw-flex tw-items-center'>
          <FontAwesomeIcon icon={faSearch} className='tw-text-gray-400' />
          <input className='tw-outline-0 tw-ml-1 tw-bg-primary tw-text-sm'
            value={search_text}
            onChange={
              (evt) => {
                setSearchText(evt.target.value);
              }
            }
            placeholder="Search"
          />
          {
            search_text
              ? <FontAwesomeIcon icon={faX} className='tw-absolute tw-right-3 tw-text-white tw-text-xxs' onClick={() => setSearchText("")} />
              : null
          }
        </div>
      </div>
      {
        all_categories?.length > 0 &&
            <CategorySelectorStrip categories={all_categories} onCategoriesSelected={(categories) => {
              if (categories.length > 0) {
                setFilteredDeliveryRoutes(
                  delivery_routes.filter(
                    (d) => d.categories?.some(
                      c => categories.some(_c => c.toLowerCase().includes(_c))
                    )
                  )
                )
              } else {
                setFilteredDeliveryRoutes(delivery_routes)
              }
            }}/>
      }
      {filtered_delivery_routes.length > 0 ? (
        <div className="tw-bg-gray-bg tw-grid tw-grid-cols-1 md:tw-grid-cols-4">
          {filtered_delivery_routes?.map((item, i) => (
            <div
              key={item._id} className={`tw-bg-white tw-rounded-xl tw-p-2 tw-gap-2 tw-flex tw-relative tw-m-1 `}
              onClick={() => window.open(`/${item._id}`,"_blank")}>
              <img src={item.image ||
                "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-04-17/168171844004tRG191tR__SUKHIBA_MASCOTVf.jpeg"}
                className='tw-w-14 tw-h-14 tw-rounded-md' />
              <div>
                {
                  item.is_private && <div className='tw-absolute tw-top-1 tw-text-white tw-rounded-l tw-right-0 tw-text-xxs tw-p-1 tw-bg-red-400'>
                    PRIVATE
                  </div>
                }
                <p className='tw-text-black tw-text-sm tw-font-bold'>
                  {item.title}
                </p>
                <p className='tw-text-[#666666] tw-text-xs tw-mt-1 line-clamp-2'>
                  {item.description}
                </p>
              </div>

            </div>
          ))}
        </div>
      ) : is_loading ? (
            <LoadingView title={"Loading.."} height="200px" />
      ) : null}

      <div className="tw-absolute tw-bottom-12 tw-flex tw-p-2 tw-items-center tw-right-0 tw-bg-gray-100 tw-rounded-l-xl"
        onClick={() => { openWhatsapp(delivery_org.wa_business_number, "Hey, i'm looking for something") }}>
        <img src={WhatsappIcon} width={34} />
      </div>
    </div>
  );
}

export default DeliveryRoutesList;
