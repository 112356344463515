import { playSound } from "base/utils/audio";
import CART_ADD_MP3 from "../audio/cart_add.mp3";
import CART_REMOVE_MP3 from "../audio/cart_remove.mp3";
import { ORDER_STATUS_DELIVERED, CURRENCY_DECIMALS, ORG_FEATURE_FLAG_DEFAULT_CONFIG } from "../constants";
import { round } from "base/utils";
import { currencyToLocale, currencyToSymbol } from "base/utils/common";

async function playCartChangeSound(diff) {
	if (diff > 0) {
		playSound(CART_ADD_MP3);
	} else if (diff < 0) {
		playSound(CART_REMOVE_MP3);
	}
}

function getCartTotal(order, cart) {
	const order_items = order?.items || {};
	const cart_items = order?.status === ORDER_STATUS_DELIVERED ? {} : cart?.items || {};
	const cp_ids = Array.from(new Set([...Object.keys(order_items), ...Object.keys(cart_items)])).filter((cp_id) => {
		let cart_item = cart_items[cp_id];
		let order_item = order_items[cp_id];
		if (order_item || cart_item.diff_qty > 0) {
			/* show in cart only if there is a diff or order.item is present */
			return true;
		}
		return false;
	});
	const abandoned_cp_ids = Object.keys(cart_items).filter(
		(cp_id) => cart_items[cp_id].diff_qty <= 0 && !cart_items[cp_id].hold_until,
	);

	let { total_saving } = cp_ids
		.map((cp_id) => {
			let cart_item = cart_items[cp_id];
			let order_item = order_items[cp_id];
			//prefer cart_item as it's usually latest updated one
			const saving =
				(cart_item?.catalog_product?.saving || order_item?.saving || 0) *
				((cart_item?.diff_qty || 0) + (order_item?.qty || 0));

			return { saving };
		})
		.reduce(
			(result, { saving }) => {
				result.total_saving += saving;
				return result;
			},
			{ total_saving: 0 },
		);

	let total_amount = cp_ids.length ? cart?.pricing?.total_amount || 0 || order?.total_amount || 0 : 0;
	let items_total = cp_ids.length ? cart?.pricing?.items_total || 0 || order?.items_total || 0 : 0;
	let discounts_total = cp_ids.length ? cart?.pricing?.discounts_total || 0 || order?.discounts_total || 0 : 0;
	let total_tax_amount = cart?.pricing?.total_tax_amount || order?.total_tax_amount || 0;
	let total_lp = cart.pricing?.estimated_loyalty_points;

	return {
		total_amount,
		total_saving: total_saving + discounts_total,
		items_total,
		total_tax_amount,
		cp_ids,
		abandoned_cp_ids,
		total_lp,
	};
}

const canModifyOrder = (order) => {
	if (order?.status === ORDER_STATUS_DELIVERED) return false;
	if (!order?._id) return true; //no order id means it's a new order
	return !!(order.can_modify_until && order.can_modify_until > new Date().getTime());
};

const getDisplayPrice = (currency, price) => {
	const formater_options = {
		style: "currency",
		currency: currency,
		minimumFractionDigits: CURRENCY_DECIMALS[currency] || 0,
	};
	return new Intl.NumberFormat(currencyToLocale(currency), formater_options).format(price / 1000);
};

const getPrice = (currency, price) => {
	if (CURRENCY_DECIMALS[currency] == 3) {
		return round(price / 1000, 3).toFixed(3);
	}
	return round(price / 1000);
};

const getSubProductDescription = (config, delimeter) => {
	if (!config?.sub_selections) return "";
	return Object.entries(config.sub_selections)
		.map(([key, value]) => (value.sub_selections ? getSubProductDescription(value, ", ") : value ? key : ""))
		.filter((val) => !!val)
		.join(delimeter || "<br />");
};

const isConfigEnable = (other_config, key, default_value) => {
	if (key in other_config) {
		return other_config[key] === "1";
	}
	return default_value;
};

const getFeatureFlag = (id, delivery_org) => {
	const config = delivery_org?.feature_flags?.[id] ||
		ORG_FEATURE_FLAG_DEFAULT_CONFIG[id] || { is_active: true, is_visible_on_inactive: false };
	return [config.is_active, config.is_visible_on_inactive];
};

function getVariantInfo(item) {
	var variants = "";
	Object.entries(item.variant_info).map(([key, val]) => {
		variants = variants + key + " : " + val + ", ";
	});
	return variants.slice(0, -2);
}

const getKeyByValue = (map, value) => {
	return Object.keys(map).find((key) => map[key] === value);
};

export {
	playCartChangeSound,
	canModifyOrder,
	getCartTotal,
	getPrice,
	getDisplayPrice,
	getSubProductDescription,
	isConfigEnable,
	getVariantInfo,
	getKeyByValue,
	getFeatureFlag,
};
